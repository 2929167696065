import { INDICATOR_TYPES, URL_TYPES_LIST } from '@/modules/config'

/**
 * Make a point array into an array of length pointNumber
 * @param points Array: the original points
 * @param pointNumber Number: The desired length
 * @param defaultValue Mixed: The value to fill
 * @param addAtBeginning Bool: Should the filler be added at the beginning?
 * @returns {*|*[]}
 */
function toXPoints({
  points,
  pointNumber,
  defaultValue,
  addAtBeginning = true
}) {
  if (points.length === pointNumber) {
    return points
  }
  if (points.length > pointNumber) {
    return points.slice(0, pointNumber)
  }

  const remainingPoints = Array.from(
    Array(pointNumber - points.length).keys()
  ).map(() => defaultValue)

  if (addAtBeginning) {
    return [...remainingPoints, ...points]
  }
  return [...points, ...remainingPoints]
}

function isTypeValid(type, showError = true) {
  // If the score type is wrong, we stop
  if (!Object.values(INDICATOR_TYPES).includes(type)) {
    showError && console.error(`Wrong indicator type "${type}"`)
    return false
  }
  return true
}

function getAverageScoreForUrlType({ barometer, type, urlType }) {
  // If the score type is wrong, we stop
  if (!isTypeValid(type)) {
    return null
  }
  // If the url type is wrong, we stop
  const urlTypeData = Object.values(URL_TYPES_LIST).find(
    (ut) => ut.key === urlType
  )
  if (!urlTypeData) {
    console.error(`Wrong url type "${urlType}"`)
    return null
  }

  const urlTypeName = urlTypeData.name
  const scoreForUrl = barometer.averages.by_url_type.find(
    (average) => average.name === urlTypeName
  )
  if (!scoreForUrl) {
    console.warn(
      `Average score for url "${urlType} and type "${type}" not found in barometer"`
    )
    return null
  }

  return scoreForUrl.scores[type]
}

function getScoreHistory({ barometer, type, pointNumber = null }) {
  // If the score type is wrong, we stop
  if (!isTypeValid(type)) {
    return null
  }

  const points = barometer.history.map((moment) => moment[`score_${type}`])
  if (!pointNumber) {
    return points
  }

  return toXPoints({
    points,
    pointNumber,
    defaultValue: null,
    addAtBeginning: true
  })
}

function getGlobalScore({ barometer, type, pointNumber = null }) {
  // If the score type is wrong, we stop
  if (!isTypeValid(type)) {
    return null
  }
  const weekValue = barometer.scores.score['7days']
  const monthValue = barometer.scores.score['28days']

  return Array.from(Array(pointNumber).keys()).map((e, i) =>
    i < pointNumber - 7 ? monthValue : weekValue
  )
}

export default {
  getAverageScoreForUrlType,
  getScoreHistory,
  getGlobalScore,
  toXPoints
}
