<template>
  <div class="afterLayeredBlocks" ref="container">
    <div class="block blockA" ref="blockA">
      <div class="content">
        <h3 class="blockTitle">Notre démarche</h3>
        <p class="blockText">La notion de web performance n'a vraiment d'intérêt que si elle peut être comparée et analysée sur le temps long. . C'est pour cette raison que nous avons développé Skor, un outil de mesure et d'analyse de la web performance fondé sur les cinq indicateurs clés de Google (Core Web Vitals).</p>
      </div>
    </div>

    <div class="block blockB" ref="blockB">
      <div class="content">
        <h3 class="blockTitle">Comparer ce qui est comparable</h3>
        <p class="blockText">Skor vous propose une mesure contextualisée de votre web performance, en comparaison à une base de données de sites qui évoluent sur le même secteur concurrentiel que vous. Ainsi, vous êtes en capacité de mieux vous situer vis-à-vis de vos concurrents et de mieux estimer votre marge de progression.</p>
        <SwitchingButton
          primaryText="J'obtiens mon rapport"
          primaryColor="cactusDudu"
          secondaryText="J'obtiens mon rapport"
          secondaryColor="soleilEte"
          @click="() => getReport()"
        />
      </div>
    </div>

    <div class="block blockC" ref="blockC">
      <div class="content">
        <h3 class="blockTitle">Analyser sur le temps long</h3>
        <p class="blockText">Skor vous propose d'historiser votre web performance, jusqu'à 28 jours, afin de suivre votre évolution. Ainsi, vous pouvez mesurer votre progression et, lorsque nécessaire, mettre en place des actions pour rectifier le tir.</p>
        <SwitchingButton
          primaryText="J'obtiens mon rapport"
          primaryColor="reveBleu"
          secondaryText="J'obtiens mon rapport"
          secondaryColor="vieEnRose"
          @click="() => getReport()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { ROUTE_INSCRIPTION } from '@/router';
import anime from 'animejs';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

export default {
  name: 'AfterLayeredBlocks',
  components: { SwitchingButton },
  props: {
    scrollController: Object,
  },
  data() {
    return {
      scene: null,
      step: null,
    };
  },
  methods: {
    animateScroll(newStep) {
      let prevTargets = [];
      let currentTargets = [];
      let nextTargets = [];

      const a = this.$refs.blockA;
      const b = this.$refs.blockB;
      const c = this.$refs.blockC;

      switch (newStep) {
        case -1:
          // nextTargets = [a, b, c];
          currentTargets = [a];
          nextTargets = [b, c];
          break;
        case 0:
          currentTargets = [a];
          nextTargets = [b, c];
          break;
        case 1:
          prevTargets = [a];
          currentTargets = [b];
          nextTargets = [c];
          break;
        case 2:
          prevTargets = [a, b];
          currentTargets = [c];
          break;
        default:
          prevTargets = [a, b];
          currentTargets = [c];
      }

      const duration = 1000;
      const easing = 'easeInOutQuad';
      anime({
        targets: [prevTargets],
        duration,
        easing,
        top: '-100%',
      });
      anime({
        targets: [currentTargets],
        duration,
        easing,
        top: '0',
      });
      anime({
        targets: [nextTargets],
        duration,
        easing,
        top: '100%',
      });
    },
    animateContent(newStep) {
      let activeTargets = [];
      let passiveTargets = [];

      const a = this.$refs.blockA.querySelectorAll('.content > *');
      const b = this.$refs.blockB.querySelectorAll('.content > *');
      const c = this.$refs.blockC.querySelectorAll('.content > *');

      const goingDown = newStep > this.step;
      let duration = 1000;
      let delay = goingDown ? 400 : 0;
      const easing = 'easeInOutQuad';
      const prevY = goingDown ? 100 : -100;

      switch (newStep) {
        case 0:
          activeTargets = [a];
          passiveTargets = [b, c];
          if (this.step < newStep) {
            duration = 300;
            delay = 0;
          }
          break;
        case 1:
          passiveTargets = [a, c];
          activeTargets = [b];
          break;
        case 2:
          passiveTargets = [a, b];
          activeTargets = [c];
          break;
        default:
          passiveTargets = [a, b, c];
          break;
      }

      anime({
        targets: passiveTargets,
        easing,
        duration,
        opacity: 0,
      });
      anime({
        targets: activeTargets,
        easing,
        delay,
        duration,
        translateY: [prevY, 0],
        translateZ: 0,
        opacity: [0, 1],
      });
    },
    initScroll() {
      const element = this.$refs.container;

      this.scene = new ScrollMagic.Scene({
        triggerElement: element,
        duration: '300%',
        triggerHook: 0,
      })
        .on('progress', (scroll) => {
          const seekValue = scroll.progress;
          const childrenCount = $(element).find('.block').length; // + 1;
          const stepSize = 1 / childrenCount;
          const newStep = Math.floor(seekValue / stepSize);

          this.setStep(newStep);
        })
        .setPin(element)
        .addTo(this.scrollController);
    },
    setStep(newStep) {
      if (newStep === this.step) {
        return;
      }

      this.animateScroll(newStep);
      this.animateContent(newStep);

      this.step = newStep;
    },
    getReport() {
      this.$router.push({ name: ROUTE_INSCRIPTION });
    },
  },
  mounted() {
    this.initScroll();
    this.setStep(-1);
  },
  beforeDestroy() {
    this.scene.remove();
  },
};
</script>
