<template>
  <div class="barometerIndicatorMark text-center">
    <div class="mark">{{ formattedScore }}/100</div>
    <p class="popinsText remark" :class="textClass">
      <v-icon class="icon" :color="buttonColor">{{ icon }}</v-icon>
      {{ buttonLabel }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'HistogramIndicatorMark',
  props: {
    score: { type: [String, Number] },
  },
  data() {
    return {
      goodJobMin: 89,
      averageJobMin: 49,
    };
  },
  computed: {
    formattedScore() {
      return parseInt(this.score, 10);
    },
    buttonLabel() {
      if (this.formattedScore > this.goodJobMin) {
        return this.$t('Indicator.Mark.goodJob');
      }
      if (this.formattedScore > this.averageJobMin) {
        return this.$t('Indicator.Mark.averageJob');
      }
      return this.$t('Indicator.Mark.badJob');
    },
    icon() {
      if (this.formattedScore > this.goodJobMin) {
        return 'check';
      }
      if (this.formattedScore > this.averageJobMin) {
        return 'trending_up';
      }
      return 'close';
    },
    textClass() {
      if (this.formattedScore > this.goodJobMin) {
        return 'cactusDudu--text';
      }
      if (this.formattedScore > this.averageJobMin) {
        return 'soleilEte--text';
      }
      return 'error--text';
    },
    buttonColor() {
      if (this.formattedScore > this.goodJobMin) {
        return 'cactusDudu';
      }
      if (this.formattedScore > this.averageJobMin) {
        return 'soleilEte';
      }
      return 'error';
    },
  },
};
</script>
