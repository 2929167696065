import { getSectors } from '@/modules/api';
export default {
  namespaced: true,
  state: {
    sectors: null,
  },
  mutations: {
    setSectors(state, sectors) {
      state.sectors = sectors;
    },
  },
  actions: {
    async getSectors(sectorStore) {
      if (!sectorStore.state.sectors) {
        const sectors = await getSectors();
        sectorStore.commit('setSectors', sectors);
      }
      return sectorStore.state.sectors;
    },
  },
};
