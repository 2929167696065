<template>
  <div class="appFooter">

    <div class="px-5">
      <div class="centered-container footerCopyright">
        <v-row>
          <div class="big-panel" id="copyright">
            <div class="copyright">
              <div class="made">
                Made with <span class="love">love</span> by Synolia
              </div>
              <div class="legal">
                © 2022-2023 Copyright Synolia Agence e-commerce, CRM, Business
                Intelligence et PIM •
                <a href="/CGU">
                  Pour lire les mentions légales, ça se passe par ici
                </a>
              </div>
            </div>
            <FooterSkorLetters />
          </div>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import FooterSkorLetters from '@/components/footer/SkorLetters.vue';
import { ROUTE_CGU } from '@/router';

export default {
  components: { FooterSkorLetters },
  data() {
    return {
      ROUTE_CGU: ROUTE_CGU,
    };
  },
};
</script>
