<template>
  <v-form ref="form">
    <v-row align="center" justify="center">
      <v-col xl="8" lg="9" class="instantForm">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="selectedUrl"
              type="url"
              :label="$t('Instant.Form.Url.label') + '*'"
              :placeholder="$t('Instant.Form.Url.placeholder')"
              hide-details="auto"
              color="blueVelvet"
              class="urlInput"
              :rules="urlRules"
              :loading="loading"
              :disabled="loading"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="4">
            <SectorSelector v-model="selectedSector" :loading="loading" />
          </v-col>

          <v-col cols="12" sm="4" class="deviceSelectorCol">
            <DeviceSelector
              v-model="selectedDevice"
              :loading="loading"
              @input="(val) => (selectedDevice = val)"
            />
          </v-col>

          <v-col cols="12" v-if="error">
            <p class="error--text mb-0">
              {{ error }}
            </p>
          </v-col>

          <v-col cols="12" align="center" class="mb-5">
            <p v-if="loading" class="my-10">Le traitement des données peut prendre quelques minutes, ne rafraîchissez pas la page, on bosse dur !</p>
            <SwitchingButton
              :primaryText="$t('Instant.Form.Submit.text1')"
              primaryColor="reveBleu"
              :secondaryText="$t('Instant.Form.Submit.text2')"
              secondaryColor="vieEnRose"
              @click="loadBarometer"
              :loading="loading"
              :disabled="loading"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { getInstant, getBarometer } from '@/modules/api';
import DeviceSelector from '@/components/barometer/DeviceSelector';
import SectorSelector from '@/components/barometer/SectorSelector';
import { mapGetters, mapMutations } from 'vuex';
import { URL_RULES } from '@/utils/form/rules';

export default {
  name: 'InstantForm',
  components: { SwitchingButton, SectorSelector, DeviceSelector },
  props: {
    url: { type: String },
    sector: { type: [String, Number] },
    device: { type: String },
  },
  data() {
    return {
      urlRules: URL_RULES,
      error: null,
      loading: false,
      selectedSector: null,
      searchedSector: null,
      selectedDevice: 'desktop',
      searchedDevice: null,
      selectedUrl: '',
      searchedUrl: null,
      instant: null,
      autoLoad: false,
    };
  },
  computed: {
    ...mapGetters({
      existingInstant: 'instant/instant',
      lastWebsite: 'website/lastWebsite',
    }),
  },
  methods: {
    ...mapMutations({
      saveInstant: 'instant/addInstant',
      setLastWebsite: 'website/setLastWebsite',
    }),
    async loadBarometer() {
      this.$refs.form.validate();
      this.error = null;
      if (this.selectedUrl) {
        this.setLastWebsite(this.selectedUrl);
      }
      // If we do not have the required datas, we do nothing
      if (!this.selectedSector || !this.selectedDevice || !this.selectedUrl) {
        return;
      }

      const shouldReloadBarometer =
        this.searchedDevice !== this.selectedDevice ||
        this.searchedSector !== this.selectedSector;
      this.searchedUrl = this.selectedUrl;
      this.searchedSector = this.selectedSector;
      this.searchedDevice = this.selectedDevice;

      let instant = this.existingInstant({
        device: this.selectedDevice,
        url: this.selectedUrl,
      });

      // If we already have instant & barometer info, we do nothing
      if (instant && !shouldReloadBarometer) {
        return;
      }

      this.loading = true;

      // If dont have the instant, we reload instant and barometer
      if (!instant) {
        instant = await getInstant(
          this.selectedSector,
          this.selectedDevice,
          this.selectedUrl
        )
          .then((response) => {
            const keys = Object.keys(response);
            // errors have code 200 so we need to test
            if (
              keys.includes('detail') &&
              keys.includes('title') &&
              keys.includes('type')
            ) {
              if (response.title === 400) {
                throw new Error('Api.Error.error400');
              }
              throw new Error(response.detail);
            }

            return response;
          })
          .catch((error) => {
            this.error = this.translateError(error.message);
            this.loading = false;
            throw error;
          });
        console.log('instant result', { instant });
        this.instant = instant;

        this.saveInstant({
          device: this.selectedDevice,
          url: this.selectedUrl,
          instant,
        });
      } else if (shouldReloadBarometer) {
        const barometer = await getBarometer(
          this.selectedSector,
          this.selectedDevice
        )
          .then((response) => {
            const keys = Object.keys(response);
            // errors have code 200 so we need to test
            if (
              keys.includes('detail') &&
              keys.includes('title') &&
              keys.includes('type')
            ) {
              if (response.title === 400) {
                throw new Error('Api.Error.error400');
              }
              throw new Error(response.detail);
            }

            return response;
          })
          .catch((error) => {
            this.error = this.translateError(error.message);
            this.loading = false;
            throw error;
          });
        this.instant = {
          ...barometer,
          instant: this.instant.instant,
        };
      }

      this.loading = false;
      this.$emit('instantLoaded', {
        instant: this.instant,
        sector: this.selectedSector,
        device: this.selectedDevice,
        url: this.selectedUrl,
      });
    },
    translateError(error) {
      if (
        error.startsWith('Too many requests: You have a rate of ') &&
        error.endsWith(' request per hour. If you need more, pease contact us.')
      ) {
        return this.$t('Api.Error.xRequestLimit', {
          x: error
            .replace('Too many requests: You have a rate of ', '')
            .replace(
              ' request per hour. If you need more, pease contact us.',
              ''
            ),
        });
      } else if (
        error.startsWith('The value "') &&
        error.endsWith('" is not a valid URL')
      ) {
        return this.$t('Api.Error.wrongUrlX', {
          x: error.replace('The value ', '').replace(' is not a valid URL', ''),
        });
      }
      return this.$t(error);
    },
  },
  created() {
    this.autoLoad = false;
    if (this.url) {
      this.selectedUrl = this.url;
      this.setLastWebsite(this.url);
    } else if (this.lastWebsite) {
      this.selectedUrl = this.lastWebsite;
    }
    if (this.device) {
      this.selectedDevice = this.device;
    }
    if (this.sector) {
      this.selectedSector = this.sector;
    }

    // If everything is pre-selected, we will load automatically
    if (this.url && this.device && this.sector) {
      this.autoLoad = true;
    }
  },
  mounted() {
    if (this.autoLoad) {
      this.loadBarometer();
      this.autoLoad = false;
    }
  },
};
</script>
