<template>
  <v-container class="centered-container webperf">
    <v-row>
      <v-col cols="12">
        <Header :title="$t('Info.headerWebperf')">
          <h1>
            SKOR
            <span>{{ $t('Info.headerWebperf') }}</span>
          </h1>
        </Header>
      </v-col>
      <v-col
        cols="12"
        md="12"
        :class="isExpanded ? 'expandedBlock' : 'collapsedBlock'"
      >
        <v-card flat color="transparent">
          <v-card-text class="webperf">
            <div class="row">
              <p class="col-md-6 col-xs-12">
                {{ $t('Info.TextWebperf.Part1.text1') }}
              </p>
              <p class="col-xs-12 col-md-6">
                {{ $t('Info.TextWebperf.Part1.text2') }}
              </p>
            </div>
            <hr class="separator" />
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <h3 class="mb-6">
                  {{ $t('Info.TextWebperf.Part2.Title.title1') }}
                  <span>{{ $t('Info.TextWebperf.Part2.Title.title2') }}</span>
                </h3>
                <p>{{ $t('Info.TextWebperf.Part2.text1') }}</p>
                <p>{{ $t('Info.TextWebperf.Part2.text2') }}</p>
                <p>{{ $t('Info.TextWebperf.Part2.text3') }}</p>
              </div>
              <div class="col-md-6 col-xs-12">
                <h3 class="mb-6">
                  {{ $t('Info.TextWebperf.Part3.Title.title1') }}
                  <span>{{ $t('Info.TextWebperf.Part3.Title.title2') }}</span>
                </h3>
                <p>{{ $t('Info.TextWebperf.Part3.text1') }}</p>
                <p>{{ $t('Info.TextWebperf.Part3.text2') }}</p>
              </div>
            </div>
            <div>
              <img
                src="/img/skor_space_performance.jpg"
                alt="web performance space"
                style="width: 100%; height: auto"
              />
            </div>
            <p class="col-md-6 col-12 txt_exergue">
              {{ $t('Info.TextWebperf.part4') }}
            </p>
            <div class="row">
              <div class="col-md-6 col-xs-12">
                <h3 class="mb-6">
                  {{ $t('Info.TextWebperf.Part5.Title.title1') }}
                  <span>{{ $t('Info.TextWebperf.Part5.Title.title2') }}</span>
                </h3>
                <p>{{ $t('Info.TextWebperf.Part5.text') }}</p>
              </div>
              <div class="col-md-6 col-xs-12">
                <h3 class="mb-6">
                  {{ $t('Info.TextWebperf.Part6.Title.title1') }}
                  <span>{{ $t('Info.TextWebperf.Part6.Title.title2') }}</span>
                </h3>
                <p>{{ $t('Info.TextWebperf.Part6.text1') }}</p>
                <p>{{ $t('Info.TextWebperf.Part6.text2') }}</p>
              </div>
            </div>
            <p class="col-md-9 col-12">
              {{ $t('Info.TextWebperf.Part7.text') }}
              <br />
              <br />
              <span class="signature">
                {{ $t('Info.TextWebperf.Part7.SynoliaTeam.part1') }}
                <br />
                {{ $t('Info.TextWebperf.Part7.SynoliaTeam.part2') }}
              </span>
            </p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-row justify="center">
        <v-btn @click="toggleExpandText" class="showMoreBtn" color="reveBleu">
          {{ this.isExpanded ? $t('Info.showLess') : $t('Info.showMore') }}
        </v-btn>
      </v-row>
      <v-col cols="12">
        <v-divider horizontal></v-divider>
      </v-col>
      <v-col cols="12">
        <Header :title="$t('Info.header1')">
          <h2>{{ $t('Info.header1') }}</h2>
        </Header>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="indicator in indicators"
        :key="indicator.key"
      >
        <v-card color="transparent" flat class="my-6">
          <v-row class="px-3">
            <v-col cols="12" class="pb-0">
              <IndicatorHeader :type="indicator.key" />
            </v-col>
          </v-row>
          <v-card-text>
            {{ indicator.description }}
          </v-card-text>
          <v-card-text class="py-0" v-if="indicator.sourceLink">
            <span class="font-weight-bold font-italic">Source</span>
            :
            <a :href="indicator.sourceLink" target="_blank">
              {{ indicator.sourceLink }}
            </a>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-divider horizontal></v-divider>
      </v-col>
      <v-col cols="12">
        <Header :title="$t('Info.header2')">
          <h2>{{ $t('Info.header2') }}</h2>
        </Header>
      </v-col>
      <v-col>
        <v-card flat color="transparent">
          <v-card-text v-html="$t('Info.text1')" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { INDICATORS_LIST } from '@/modules/config';
import Header from '@/components/utils/Header';
import IndicatorHeader from '@/components/barometer/indicator/Header';

export default {
  components: { Header, IndicatorHeader },
  data: () => ({
    isExpanded: false,
  }),
  computed: {
    indicators() {
      return INDICATORS_LIST.map((indicator) => ({
        ...indicator,
        title: this.$t('Indicators.' + indicator.key + '.title'),
        subtitle: this.$t('Indicators.' + indicator.key + '.subtitle'),
        description: this.$t('Indicators.' + indicator.key + '.description'),
        source: indicator.sourceLink,
        color: indicator.color.primary,
      }));
    },
  },
  methods: {
    toggleExpandText() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>
