<template>
  <v-card class="simple-small-indicator" :class="isBig ? 'simple-small-indicator-big' : 'simple-small-indicator-small'">
    <v-card-text>
      <p class="score">{{ score }}</p>
      <p class="scoreTitle">{{ title }}</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseSimpleSmallIndicator',
  props: {
    score: String,
    title: String,
    isBig: Boolean,
  },
};
</script>
