<script>
import BaseSmallIndicator from '@/components/barometer/smallIndicator/BaseSmallIndicator.vue'

export default {
  extends: BaseSmallIndicator,
  name: 'SmallIndicatorSiteAverage',
  computed: {
    title() {
      return 'Moyenne sur les 7 derniers jours.'
    },
    score() {
      return this.barometer.instant.score.instant
    },
    leftScore() {
      const result = ((this.score * 100) / this.barometer.scores.score['7days']) - 100 // eslint-disable-line prettier/prettier
      return this.to2Decimal(result)
    },
    leftText() {
      return 'par rapport au secteur surles 7 derniers jours'
    },
    rightScore() {
      const result = ((this.score * 100) / this.barometer.scores.score.global_28days) - 100 // eslint-disable-line prettier/prettier
      return this.to2Decimal(result)
    },
    rightText() {
      return 'par rapport au secteur sur les 28 derniers jours'
    },
    isValid() {
      return this.barometer.instant
    }
  }
}
</script>
