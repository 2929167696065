export default {
  namespaced: true,
  state: {
    lastWebsite: null,
  },
  getters: {
    lastWebsite: (state) =>state.lastWebsite,
  },
  mutations: {
    setLastWebsite(state, website) {
      state.lastWebsite = website;
    },
  },
};
