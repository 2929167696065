<template>
  <v-container class="footerSkorLetters">
    <v-row>
      <v-col md="2" class="d-none d-md-block"></v-col>
      <v-col cols="12" md="8">
        <v-row>
          <v-col cols="3" align="center" justify="center">
            <div
              class="letter-container letter-container-s random-move"
              :data-power="power"
              :data-speed="speed"
            >
              <div class="letter">S</div>
              <div class="skor skor-letter letter-s1 random-move"></div>
              <div class="skor skor-letter letter-s2 random-move"></div>
            </div>
          </v-col>
          <v-col cols="3" align="center" justify="center">
            <div
              class="letter-container letter-container-k random-move"
              :data-power="power"
              :data-speed="speed"
            >
              <div class="letter">K</div>
              <div class="skor skor-letter letter-k random-move"></div>
            </div>
          </v-col>
          <v-col cols="3" align="center" justify="center">
            <div
              class="letter-container letter-container-o random-move"
              :data-power="power"
              :data-speed="speed"
            >
              <div class="letter">O</div>
              <div class="skor skor-letter letter-o random-move"></div>
            </div>
          </v-col>
          <v-col
            cols="3"
            align="center"
            justify="center"
          >
            <div
              class="letter-container letter-container-r random-move"
              :data-power="power"
              :data-speed="speed"
            >
              <div class="letter">R</div>
              <div class="skor skor-letter letter-r random-move"></div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col md="2" class="d-none d-md-block"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FooterSkorLetters',
  data() {
    return {
      power: 10,
      speed: 10,
    };
  },
};
</script>
