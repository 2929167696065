<template>
  <div class="scrollingTextBlock">
    <div class="scrollingTextContainerEventDispatcher" ref="dispatcher"></div>
    <div class="scrollingTextContainer" ref="container">
      <div class="scrollingTextSubContainer" ref="content">
        <div class="scrollingTextContent">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollMagic from 'scrollmagic';

export default {
  name: 'AutoScrollingText',
  props: {
    text: String,
    scrollController: Object,
  },
  data() {
    return {
      scene: null,
    };
  },
  methods: {
    initScroll() {
      const contentWidth = this.$refs.content.getBoundingClientRect().width;
      const contentHeight = this.$refs.content.offsetHeight;
      const windowWidth = window.innerWidth;
      if (contentWidth < windowWidth) {
        // If there is no need to scroll, we do not do it
        return;
      }
      const duration = ((contentWidth - windowWidth) * 100) / contentHeight;
      this.scene = new ScrollMagic.Scene({
        vertical: false,
        triggerElement: this.$refs.dispatcher,
        duration: `${duration / 2}%`,
      })
        .on('progress', (scroll) => {
          const seekValue = scroll.progress;
          const contentWidth = this.$refs.content.getBoundingClientRect().width;
          const difference = contentWidth - windowWidth;
          this.$refs.content.style.transform = `translateX(-${(difference * seekValue)}px)`;
        })
        .setPin(this.$refs.container)
        .addTo(this.scrollController);
    },
  },
  mounted() {
    this.initScroll();
  },
  beforeDestroy() {
    this.scene.remove();
  },
};
</script>
