<template>
  <div>
    <v-toolbar
      class="site-header"
      comfortable
      height="90"
      align="center"
      color="transparent"
      elevation="0"
    >
      <v-container>
        <div class="d-flex justify-space-between">
          <HeaderLogo version="light" />
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$emit('close-dialog')">
            <div class="menu-icon-container">
              <img
                class="menu-part-dialog top-part-dialog"
                src="../../assets/images/menu_part.svg"
                style="transform: rotate(45deg)"
              />
              <img
                class="menu-part-dialog middle-part-dialog"
                src="../../assets/images/menu_part.svg"
                style="opacity: 0"
              />
              <img
                class="menu-part-dialog bottom-part-dialog"
                src="../../assets/images/menu_part.svg"
                style="transform: rotate(-45deg)"
              />
            </div>
          </v-btn>
        </div>
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>
import HeaderLogo from './HeaderLogo.vue';

export default {
  components: { HeaderLogo },
};
</script>
