<template>
  <div></div>
</template>

<script>
import anime from 'animejs';
import $ from 'jquery';

export default {
  name: 'RandomMover',
  watch: {
    $route() {
      setTimeout(() => {
        this.initRandomMoves();
      }, 500);
    },
  },
  methods: {
    getDistance(x1, y1, x2, y2) {
      let y = x2 - x1;
      let x = y2 - y1;

      return Math.sqrt(x * x + y * y);
    },
    randomMove(element, force = false) {
      const $element = $(element);
      if (!force && $element.attr('data-initialized') === '1') {
        return;
      }
      $element.attr('data-initialized', 1);
      var scale = 1;
      if ($element.css('scale') > 0) {
        scale = $element.css('scale');
      }
      var scaler = 1 / scale;

      var power = 10;
      if ($element.data('power')) {
        power = $element.data('power');
      }

      var speed = 10;
      if ($element.data('speed')) {
        speed = $element.data('speed');
      }

      const powerScaler = power * scaler;
      var randomX = anime.random(-powerScaler, powerScaler);
      var randomY = anime.random(-powerScaler, powerScaler);
      var matrix = $element.css('transform').replace(/[^0-9\-.,]/g, '').split(',');
      var x = matrix[12] || matrix[4];
      var y = matrix[13] || matrix[5];

      if (!x) x = 0;
      if (!y) y = 0;

      var distance = this.getDistance(randomX, randomY, x, y);

      anime({
        targets: element,
        translateX: () => randomX,
        translateY: () => randomY,
        easing: 'linear',
        duration: () => distance * 1000 / speed,
        complete: () => {
          this.randomMove(element, true);
        },
      });
    },
    initRandomMoves() {
      $('.random-move').each((id, element) => {
        this.randomMove(element);
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.initRandomMoves();
    }, 500);
  },
};
</script>
