<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    :scrim="false"
    :transition="null"
    theme="dark"
    class="menu-dialog"
  >
    <template v-slot:activator="{}">
      <v-btn
        sm="6"
        class="burger-logo"
        icon
        variant="text"
        cols="3"
        @click="toggleMenu"
        @mouseover="menuBurgerOvered()"
        @mouseleave="menuBurgerLeaved()"
      >
        <div class="menu-icon-container">
          <img
            class="menu-part top-part"
            src="../../assets/images/menu_part.svg"
          />
          <img
            class="menu-part middle-part"
            src="../../assets/images/menu_part.svg"
          />
          <img
            class="menu-part bottom-part"
            src="../../assets/images/menu_part.svg"
          />
        </div>
        <div
          :class="
            isClose
              ? 'circle-transition is-hidden'
              : 'circle-transition is-active'
          "
        ></div>
      </v-btn>
    </template>
    <v-card
      class="d-flex flex-column menu-dialog-open"
      :class="backgroundColor"
    >
      <MenuHeader @close-dialog="toggleMenu()" />
      <v-container class="v-centered-container menu-dialog-container" justify="center" align="center">
        <v-row cols="12" gap="2">
          <v-col
            cols="12"
            md="4"
            class="text-center pa-0 menu-block"
            :class="getMenuClass(menus.COMPARE)"
            @mouseover="onMenuHoverChange(menus.COMPARE)"
            @mouseleave="onMenuHoverChange(null)"
            @click="() => linkClick({ name: ROUTE_INSTANT })"
          >
            <span class="menu-link pa-6">
              <div class="menu-text-box">
                <h2 class="menu-text white--text">{{ $t('Menu.instant.title') }}</h2>
              </div>
              <span class="menu-sub-text">
                <span class="underlined">{{ $t('Menu.instant.descA') }}</span>
                {{ $t('Menu.instant.descB') }}
              </span>
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="text-center pa-0 menu-block"
            :class="getMenuClass(menus.ANALYSE)"
            @mouseover="onMenuHoverChange(menus.ANALYSE)"
            @mouseleave="onMenuHoverChange(null)"
            @click="() => linkClick({ name: ROUTE_INSCRIPTION })"
          >
            <span class="menu-link pa-6">
              <div class="menu-text-box">
                <h2 class="menu-text white--text">{{ $t('Menu.inscription.title') }}</h2>
              </div>
              <span class="menu-sub-text">
                <span class="underlined">{{ $t('Menu.inscription.descA') }}</span>
                {{ $t('Menu.inscription.descB') }}
              </span>
            </span>
          </v-col>
          <v-col
            cols="12"
            md="4"
            class="text-center pa-0 menu-block"
            :class="getMenuClass(menus.IMPROVE)"
            @mouseover="onMenuHoverChange(menus.IMPROVE)"
            @mouseleave="onMenuHoverChange(null)"
            @click="() => linkClick({ name: ROUTE_AFTER })"
          >
            <a class="menu-link pa-6">
              <div class="menu-text-box">
                <h2 class="menu-text white--text">{{ $t('Menu.and_after.title') }}</h2>
              </div>
              <span class="menu-sub-text">
                <span class="underlined">{{ $t('Menu.and_after.descA') }}</span>
                {{ $t('Menu.and_after.descB') }}
              </span>
            </a>
          </v-col>
        </v-row>
      </v-container>
      <v-spacer class="flex-grow-1"></v-spacer>
      <MenuFooter :loveColor="loveColor" />
    </v-card>
  </v-dialog>
</template>

<script>
/* eslint-disable */
import { ROUTE_INSTANT, ROUTE_INSCRIPTION, ROUTE_AFTER } from '@/router';
import MenuHeader from './MenuHeader.vue';
import MenuFooter from './MenuFooter.vue';
import anime from 'animejs';

export default {
  components: {
    MenuHeader,
    MenuFooter,
  },
  data() {
    return {
      backgroundColor: '',
      loveColor: 'vieEnRose--text',
      isHovered: false,
      burgerIsHovered: false,
      compareIsHovered: false,
      analyseIsHovered: false,
      improveIsHovered: false,
      menus: {
        COMPARE: 'COMPARE',
        ANALYSE: 'ANALYSE',
        IMPROVE: 'IMPROVE',
      },
      hoveredMenu: null,
      dialog: false,
      isOpen: false,
      isClose: true,
      ROUTE_INSTANT: ROUTE_INSTANT,
      ROUTE_INSCRIPTION: ROUTE_INSCRIPTION,
      ROUTE_AFTER: ROUTE_AFTER,
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  methods: {
    onMenuHoverChange(menu) {
      this.hoveredMenu = menu || null;

      let color = '';
      switch (menu) {
        case this.menus.COMPARE:
          this.loveColor = 'soleilEte--text';
          color = 'rgb(178, 29, 255)';
          break;
        case this.menus.ANALYSE:
          this.backgroundColor = 'blue-menu';
          this.loveColor = 'vieEnRose--text';
          color = 'rgb(1, 190, 254)';
          break;
        case this.menus.IMPROVE:
          this.backgroundColor = 'orange-menu';
          this.loveColor = 'purpleRain--text';
          color = 'rgb(255, 181, 1)';
          break;
        default:
          this.loveColor = 'vieEnRose--text';
          color = 'rgb(153 204 0)';
      }

      anime({
        targets: '.v-dialog .menu-dialog-open',
        backgroundColor: color,
        duration: 400,
        easing: 'easeInOutQuad',
      });
    },
    getMenuClass(menu) {
      if (this.isMobile || this.hoveredMenu === menu) {
        return 'hovered';
      }
      return '';
    },
    animateBurgerDialogOut() {
      anime({
        targets: '.top-part',
        translateX: '0',
        translateY: '-12',
        rotate: '0',
        filter: 'invert(0)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.middle-part',
        opacity: 1,
        duration: 1,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.bottom-part',
        translateX: '0',
        translateY: '12',
        rotate: '0',
        filter: 'invert(0)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
    },
    animateCloseDialogOut() {
      anime({
        targets: '.top-part-dialog',
        translateX: '0',
        translateY: '-12',
        rotate: '0',
        filter: 'invert(0)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.middle-part-dialog',
        opacity: 1,
        filter: 'invert(0)',
        duration: 1,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.bottom-part-dialog',
        translateX: '0',
        translateY: '12',
        rotate: '0',
        filter: 'invert(0)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
    },
    animateCloseDialogIn() {
      anime({
        targets: '.top-part-dialog',
        translateX: '0',
        translateY: '0',
        rotate: '45',
        filter: 'invert(1)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.middle-part-dialog',
        opacity: 0,
        filter: 'invert(1)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.bottom-part-dialog',
        translateX: '0',
        translateY: '0',
        rotate: '-45',
        filter: 'invert(1)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
    },
    animateBurgerDialogIn() {
      anime({
        targets: '.top-part',
        translateX: '0',
        translateY: '0',
        rotate: '45',
        filter: 'invert(1)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.middle-part',
        opacity: 0,
        duration: 1,
        easing: 'easeInOutQuad',
      });
      anime({
        targets: '.bottom-part',
        translateX: '0',
        translateY: '0',
        rotate: '-45',
        filter: 'invert(1)',
        duration: 200,
        easing: 'easeInOutQuad',
      });
    },
    dialogInAnim() {
      this.animateCloseDialogIn();
      this.animateBurgerDialogIn();
    },
    dialogOutAnim() {
      this.animateBurgerDialogOut();
      this.animateCloseDialogOut();
    },
    toggleMenu(isOpen = undefined) {
      this.isOpen = isOpen === undefined ? !this.isOpen : undefined;
      if (this.isClose) {
        this.isClose = !this.isClose;
        anime({
          targets: '.circle-transition',
          scale: {
            value: 100,
            duration: 500,
            easing: 'easeInOutQuad',
          },
          complete: () => {
            this.dialog = true;
            setTimeout(function () {
              anime({
                targets: '.menu-link',
                translateY: [60, 0],
                easing: 'easeOutExpo',
                duration: 500,
              });
            }, 10); // 200 milliseconds (.2 seconds) delay
          },
        });
        this.dialogInAnim();
      } else {
        this.dialogOutAnim();
        anime({
          targets: '.menu-link',
          translateY: [0, 60],
          easing: 'easeOutExpo',
          duration: 500,
          complete: () => {
            this.dialog = false;
          },
        });
        anime({
          targets: '.circle-transition',
          scale: {
            value: 1,
            duration: 500,
            delay: 500,
            easing: 'easeInOutQuad',
          },
          begin: () => {},
          complete: () => {
            this.isClose = !this.isClose;
          },
        });
      }
    },
    menuBurgerOvered() {
      if (!this.burgerIsHovered) {
        this.burgerIsHovered = true;
        anime({
          targets: '.top-part',
          translateX: '6',
          translateY: [-12, -12],
          duration: 200,
          easing: 'easeInOutQuad',
        });
        anime({
          targets: '.bottom-part',
          translateX: '-6',
          translateY: [12, 12],
          duration: 200,
          easing: 'easeInOutQuad',
        });
      }
    },
    menuBurgerLeaved() {
      if (this.burgerIsHovered) {
        this.burgerIsHovered = false;
        anime({
          targets: '.top-part',
          translateX: '0',
          translateY: '-12',
          duration: 200,
          easing: 'easeInOutQuad',
        });
        anime({
          targets: '.bottom-part',
          translateX: '0',
          translateY: '12',
          duration: 200,
          easing: 'easeInOutQuad',
        });
      }
    },
    linkClick(destination) {
      this.$router.push(destination);
      this.toggleMenu(false);
    },
  },
};
</script>
