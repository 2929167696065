<template>
  <v-select
    solo
    ref="selector"
    :class="className"
    hide-details="auto"
    :aria-label="ariaLabel ? ariaLabel : label"
    :value="value"
    :items="items"
    :label="label"
    :loading="loading"
    :item-text="itemText"
    loader-height="5px"
    :rules="rules"
    @input="(value) => $emit('input', value)"
    @click="(value) => $emit('click', value)"
    @focus="() => onFocus()"
  >
    <template v-slot:append>
      <v-icon color="blueVelvet">expand_more</v-icon>
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'Select',
  props: {
    items: { type: Array },
    value: { type: String },
    label: { type: String },
    ariaLabel: { type: String },
    itemText: { type: String },
    loading: { type: Boolean, required: false },
    className: { type: String },
    rules: { type: Array },
  },
  methods: {
    onFocus() {
      const btn = this.$refs.selector.$el.querySelector('[role="button"]');
      btn.dispatchEvent(new Event('click'));
    },
  },
};
</script>
