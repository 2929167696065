<template>
  <v-row>
    <v-col cols="12">
      <h2>Nos formulaires</h2>
    </v-col>
    <v-col cols="12" md="4">
      <h3>Sélecteur</h3>
      <Select label="Default" :items="selectChoices" />
    </v-col>
    <v-col cols="12" md="4">
      <h3>Champ texte</h3>
      <v-text-field
        label="L'url de votre site web*"
        type="input"
        placeholder="www.monsupersite.com"
        outlined
        hide-details="auto"
        color="blueVelvet"
        :rules="[required]"
        class="skor-input"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import Select from '@/components/utils/Select'
export default {
  name: 'ButtonStyleGuide',
  components: {
    Select
  },
  data() {
    return {
      selectChoices: ['Choix 1', 'Choix 2', 'Choix 3', 'Choix 4']
    }
  },
  methods: {
    required (v) {
      return !!v || 'Field is required'
    },
  }
}
</script>

<style>
.skor-input >>> .error--text {
  color: green !important;
}
</style>
