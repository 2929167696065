import Vue from 'vue';
import VueRouter from 'vue-router';

import Dataset from '@/views/Dataset';
import Info from '@/views/Info';
import Home from '@/views/Home';
import CGU from '@/views/CGU';
import Instant from '@/views/Instant';
import StyleGuide from '@/views/StyleGuide.vue';
import Inscription from '@/views/Inscription.vue';
import NotFound from '@/views/404.vue';
import After from '@/views/After.vue';

Vue.use(VueRouter);

export const ROUTE_HOME = 'Home';
export const ROUTE_DATASET = 'Dataset';
export const ROUTE_INFO = 'Info';
export const ROUTE_CGU = 'CGU';
export const ROUTE_INSTANT = 'Instant';
export const ROUTE_STYLEGUIDE = 'Styleguide';
export const ROUTE_INSCRIPTION = 'Inscription';
export const ROUTE_NOT_FOUND = 'NotFound';
export const ROUTE_AFTER = 'After';

export const TITLE_HOME =
  'Skor mesure la web performance de votre site e-commerce';
export const TITLE_DATASET = 'Skor -  Base de données';
export const TITLE_INFO = "Skor -  Qu'est ce que la web performance ?";
export const TITLE_CGU = 'Skor -  CGU et Mentions légales';
export const TITLE_INSTANT =
  'Skor vous permet de comparer la web performance de votre site e-commerce';
export const TITLE_INSCRIPTION =
  "Skor analyse l'évolution de la web performance de votre site e-commerce";
export const TITLE_NOT_FOUND = 'Skor -  404';
export const TITLE_AFTER =
  'Skor vous aide à comprendre les leviers de la web performance';

const routes = [
  {
    path: '/',
    name: ROUTE_HOME,
    component: Home,
    meta: { title: TITLE_HOME },
  },
  {
    path: '/dataset',
    name: ROUTE_DATASET,
    component: Dataset,
    meta: { title: TITLE_DATASET },
  },
  {
    path: '/info',
    name: ROUTE_INFO,
    component: Info,
    meta: { title: TITLE_INFO },
  },
  {
    path: '/cgu',
    name: ROUTE_CGU,
    component: CGU,
    meta: { title: TITLE_CGU },
  },
  {
    path: '/mon-skor/:url?/:sector?/:device?',
    name: ROUTE_INSTANT,
    component: Instant,
    meta: { title: TITLE_INSTANT },
  },
  {
    path: '/styleguide',
    name: ROUTE_STYLEGUIDE,
    component: StyleGuide,
    meta: { title: 'Styleguide' },
  },
  {
    path: '/mon-rapport',
    name: ROUTE_INSCRIPTION,
    component: Inscription,
    meta: { title: TITLE_INSCRIPTION },
  },
  {
    path: '/et-apres',
    name: ROUTE_AFTER,
    component: After,
    meta: { title: TITLE_AFTER },
  },
  {
    path: '/:catchAll(.*)',
    name: ROUTE_NOT_FOUND,
    component: NotFound,
    meta: { title: TITLE_NOT_FOUND },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  },
});

router.beforeEach((to, from, next) => {
  // Styleguide should not be accessible in production mode
  if (to.path === '/styleguide' && process.env.NODE_ENV !== 'development') {
    next({ name: ROUTE_NOT_FOUND });
  }
  next();
});

export default router;
