<template>
  <v-col cols="12">
    <v-row>
      <v-col cols="12" class="px-0">
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <HistogramIndicator
              :type="INDICATOR_TYPES.LCP"
              :instant="instant"
            />
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <HistogramIndicator :type="INDICATOR_TYPES.SI" :instant="instant" />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="px-0">
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <HistogramIndicator
              :type="INDICATOR_TYPES.FCP"
              :instant="instant"
            />
          </v-col>
          <v-col cols="12" md="6" class="py-0">
            <HistogramIndicator
              :type="INDICATOR_TYPES.TBT"
              :instant="instant"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="px-0">
        <v-row>
          <v-col cols="12" md="6" class="py-0">
            <HistogramIndicator
              :type="INDICATOR_TYPES.CLS"
              :instant="instant"
            />
          </v-col>

          <v-col cols="12" md="6" class="instantEndCard py-0">
            <v-card>
              <v-card-text align="center">
                <div>
                  <h3 class="endCardTitle white--text">
                    Vous souhaitez en savoir plus sur votre web performance ?
                  </h3>
                  <p class="endCardText white--text">
                    Analysez l'évolution de la web performance de votre site et
                    de votre secteur concurrentiel en demandant votre rapport
                    détaillé sur une période de 28 jours.
                  </p>
                  <SwitchingButton
                    primaryText="J'obtiens mon rapport"
                    primaryColor="reveBleu"
                    secondaryText="J'obtiens mon rapport"
                    secondaryColor="vieEnRose"
                    @click="() => getReport()"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import HistogramIndicator from '@/components/barometer/histogramIndicator/Indicator.vue';
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { INDICATOR_TYPES } from '@/modules/config';

export default {
  name: 'InstantBigIndicators',
  components: { SwitchingButton, HistogramIndicator },
  props: {
    instant: Object,
    getReport: Function,
  },
  data() {
    return {
      INDICATOR_TYPES,
    };
  },
};
</script>
