// name matches API results
export const INDICATOR_TYPES = {
  CLS: 'cls',
  SI: 'si',
  FCP: 'fcp',
  LCP: 'lcp',
  TBT: 'tbt',
};
export const URL_TYPES = {
  HOME: 'home',
  LISTING: 'listing',
  PRODUCT: 'product',
};
export const URL_TYPES_LIST = [
  {
    key: 'home',
    name: 'Homepage',
  },
  {
    key: 'listing',
    name: 'Listing',
  },
  {
    key: 'product',
    name: 'Fiche',
  },
];

export const INDICATOR_COLORS = {
  [INDICATOR_TYPES.LCP]: {
    name: 'reveBleu',
    primary: '#01BEFE',
    secondary: '#008FBF',
    tertiary: '#006080',
    quaternary: '#003040',
  },
  [INDICATOR_TYPES.FCP]: {
    name: 'cactusDudu',
    primary: '#99CC00',
    secondary: '#86B300',
    tertiary: '#698C00',
    quaternary: '#394D00',
  },
  [INDICATOR_TYPES.TBT]: {
    name: 'vieEnRose',
    primary: '#FF006D',
    secondary: '#BF0053',
    tertiary: '#800037',
    quaternary: '#40001C',
  },
  [INDICATOR_TYPES.CLS]: {
    name: 'purpleRain',
    primary: '#B24DFF',
    secondary: '#8539BF',
    tertiary: '#592680',
    quaternary: '#2C1340',
  },
  [INDICATOR_TYPES.SI]: {
    name: 'soleilEte',
    primary: '#FFB501',
    secondary: '#E6A400',
    tertiary: '#BF8900',
    quaternary: '#402E00',
  },
  sector: {
    primary: '#805B00',
    secondary: '#604400',
  },
  average: {
    primary: '#FFB501',
    secondary: '#c08800',
  },
};

export const INDICATORS_LIST = [
  {
    key: INDICATOR_TYPES.LCP,
    code: 'score_lcp',
    color: {
      primary: INDICATOR_COLORS.lcp.primary,
      secondary: INDICATOR_COLORS.lcp.secondary,
    },
    sourceLink: 'https://web.dev/lcp/',
  },
  {
    key: INDICATOR_TYPES.FCP,
    code: 'score_fcp',
    color: {
      primary: INDICATOR_COLORS.fcp.primary,
      secondary: INDICATOR_COLORS.fcp.secondary,
    },
    sourceLink: 'https://web.dev/fcp/',
  },
  {
    key: INDICATOR_TYPES.CLS,
    code: 'score_cls',
    color: {
      primary: INDICATOR_COLORS.cls.primary,
      secondary: INDICATOR_COLORS.cls.secondary,
    },
    sourceLink: 'https://web.dev/cls/',
  },
  {
    key: INDICATOR_TYPES.SI,
    code: 'score_si',
    color: {
      primary: INDICATOR_COLORS.si.primary,
      secondary: INDICATOR_COLORS.si.secondary,
    },
    sourceLink: '',
  },
  {
    key: INDICATOR_TYPES.TBT,
    code: 'score_tbt',
    color: {
      primary: INDICATOR_COLORS.tbt.primary,
      secondary: INDICATOR_COLORS.tbt.secondary,
    },
    sourceLink: 'https://web.dev/tbt/',
  },
];
