<template>
  <div class="afterBlocksWithLogo">
    <div class="logoParts" :class="logoPartsClass">
      <img
        class="random-move logoPart logoPart-s1"
        src="../../assets/images/S-logo1.png"
        alt="S"
      />
      <img
        class="random-move logoPart logoPart-s2"
        src="../../assets/images/S-logo2.png"
        alt="S"
      />
      <img
        class="random-move logoPart logoPart-k"
        src="../../assets/images/K-logo.png"
        alt="K"
      />
      <img
        class="random-move logoPart logoPart-o"
        src="../../assets/images/O-logo.png"
        alt="O"
      />
      <img
        class="random-move logoPart logoPart-r"
        src="../../assets/images/R-logo.png"
        alt="R"
      />
    </div>
    <v-container>
      <div ref="container" style="height: 100vh; overflow: hidden; position: relative;">
        <div class="block blockA" ref="blockA">
          <div class="content">
            <h1>Et après ?</h1>
            <p class="reveBleu--text">
              Synolia vous aide à comprendre les leviers de la web performance et
              vous accompagne à la mise en place d'actions concrètes pour
              améliorer votre Skor !
            </p>
            <SwitchingButton
                primaryText="Contactez Synolia !"
                primaryColor="reveBleu"
                secondaryText="Contactez Synolia !"
                secondaryColor="vieEnRose"
                href="https://www.synolia.com/contact/"
                target="_blank"
            />
          </div>
        </div>
        <div class="block blockB" ref="blockB">
          <div class="content">
            <h2 class="blockBTitle">
              <span>Pourquoi mesurer</span>
              Votre Skor en e-commerce ?
            </h2>
            <p class="reveBleu--text">
              La recherche de performance est aujourd'hui la clé en e-commerce.
              Tout le monde est capable de mettre en place un site de vente en
              ligne. Mais seuls les plus performants bénéficient d'une
              visibilité optimale !
            </p>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import anime from 'animejs/lib/anime.es.js';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

export default {
  name: 'AfterBlocksWithLogo',
  components: { SwitchingButton },
  props: {
    scrollController: Object,
  },
  data() {
    return {
      step: -1,
    };
  },
  computed: {
    logoPartsClass() {
      return `logoParts-step${this.step}`;
    },
  },
  methods: {
    animateScroll(newStep) {
      let prevTargets = [];
      let currentTargets = [];
      let nextTargets = [];
      switch (newStep) {
        case 0:
          currentTargets = [this.$refs.blockA];
          nextTargets = [this.$refs.blockB];
          break;
        case 1:
        default:
          prevTargets = [this.$refs.blockA];
          currentTargets = [this.$refs.blockB];
          break;
      }
      anime({
        targets: [prevTargets],
        duration: 500,
        easing: 'easeInQuad',
        top: '-100%',
      });
      anime({
        targets: [currentTargets],
        duration: 500,
        easing: 'easeInQuad',
        top: '0',
      });
      anime({
        targets: [nextTargets],
        duration: 500,
        easing: 'easeInQuad',
        top: '100%',
      });
    },
    animateContent(newStep) {
      let activeTargets = [];
      let passiveTargets = [];
      switch (newStep) {
        case 0:
          activeTargets = [this.$refs.blockA.querySelectorAll('.content > *')];
          passiveTargets = [this.$refs.blockB.querySelectorAll('.content > *')];
          break;
        case 1:
          passiveTargets = [this.$refs.blockA.querySelectorAll('.content > *')];
          if (this.step < 1) {
            activeTargets = [this.$refs.blockB.querySelectorAll('.content > *')];
          }
          break;
        case 2:
        default:
          passiveTargets = [this.$refs.blockA.querySelectorAll('.content > *')];
          break;
      }

      anime({
        targets: passiveTargets,
        easing: "easeOutExpo",
        duration: 500,
        opacity: 0,
      });
      anime({
        targets: activeTargets,
        easing: "easeOutExpo",
        duration: 500,
        translateY: [100,0],
        translateZ: 0,
        opacity: [0,1],
      });
    },
    setStep(newStep) {
      if (newStep === this.step) {
        return;
      }

      this.animateScroll(newStep);
      this.animateContent(newStep);

      this.step = newStep;
    },
    initScroll() {
      const element = this.$refs.container;

      new ScrollMagic.Scene({
        triggerElement: element,
        duration: '100%',
        triggerHook: 0,
      })
        .on('progress', (scroll) => {
          const seekValue = scroll.progress;
          const childrenCount = $(element).find('.block').length + 1;
          const stepSize = 1 / childrenCount;
          const newStep = Math.floor(seekValue / stepSize);

          this.setStep(newStep);
        })
        .setPin(element)
        .addTo(this.scrollController);
    },
  },
  mounted() {
    this.initScroll();
    this.setStep(0);
  },
};
</script>
