<template>
  <div class="barometerIndicatorMark text-center">
    <div class="mark">{{ formattedScore }}/100</div>
    <div class="mark-info">{{ $t('Indicator.Mark.averageOn7Days') }}</div>
    <v-btn
      rounded
      x-large
      :color="buttonColor"
      class="popinsText white--text"
      >{{ buttonLabel }}</v-btn
    >
  </div>
</template>
<script>
export default {
  name: 'BarometerIndicatorMark',
  props: {
    score: { type: [String, Number] }
  },
  data() {
    return {
      goodJobMin: 89,
      averageJobMin: 49
    }
  },
  computed: {
    formattedScore() {
      return parseInt(this.score, 10)
    },
    buttonLabel() {
      if (this.formattedScore > this.goodJobMin) {
        return this.$t('Indicator.Mark.goodJob')
      }
      if (this.formattedScore > this.averageJobMin) {
        return this.$t('Indicator.Mark.averageJob')
      }
      return this.$t('Indicator.Mark.badJob')
    },
    buttonColor() {
      if (this.formattedScore > this.goodJobMin) {
        return 'cactusDudu'
      }
      if (this.formattedScore > this.averageJobMin) {
        return 'soleilEte'
      }
      return 'error'
    }
  }
}
</script>
