<template>
  <div class="deviceSelector">
    <span @click="() => setValue(0)">{{ $t('Instant.Form.Device.desktop') }}</span>
    <v-switch
      v-model="model"
      inset
      @change="(vale) => onChange(vale)"
      :disabled="loading"
    ></v-switch>
    <span @click="() => setValue(1)">{{ $t('Instant.Form.Device.mobile') }}</span>
  </div>
</template>

<script>
export default {
  name: 'DeviceSelector',
  props: {
    value: { type: String },
    loading: { type: Boolean, required: false },
  },
  data() {
    return {
      model: true,
    };
  },
  methods: {
    setValue(val) {
      this.model = val;
    },
    onChange() {
      this.$emit('input', this.model === true ? 'mobile' : 'desktop');
    },
  },
  mounted() {
    this.model = this.value === 'mobile';
    this.onChange();
  },
};
</script>
