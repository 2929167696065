<template>
  <div class="panel-description-block">
    <v-row>
      <v-col cols="12" md="6" class="panel-description-left">
        <div class="text-container">
          <h2>
            La web performance de votre site e-commerce se mesure avec SKOR !
          </h2>
          <p>
            Skor est un outil qui mesure la web performance de votre site
            e-commerce (desktop et mobile) en s'appuyant sur cinq indicateurs de
            Google Lighthouse. De plus, Skor vous permet de comparer les
            résultats de votre web performance à ceux de vos concurrents par
            domaine d'activité. Ainsi, vous pouvez vous situer et identifier vos
            axes d'amélioration.
          </p>
        </div>
      </v-col>
      <v-col cols="12" md="6" class="panel-description-right">
        <div class="flying-indicator indicator-lcp random-move">
          <IndicatorHeader :type="INDICATOR_TYPES.LCP" />
        </div>
        <div class="flying-indicator indicator-tbt random-move">
          <IndicatorHeader :type="INDICATOR_TYPES.TBT" />
        </div>
        <div class="flying-indicator indicator-fcp random-move">
          <IndicatorHeader :type="INDICATOR_TYPES.FCP" />
        </div>
        <div class="flying-indicator indicator-cls random-move">
          <IndicatorHeader :type="INDICATOR_TYPES.CLS" />
        </div>
        <div class="flying-indicator indicator-tti random-move">
          <IndicatorHeader :type="INDICATOR_TYPES.SI" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import IndicatorHeader from '@/components/barometer/indicator/Header.vue';
import {INDICATOR_TYPES} from '@/modules/config';

export default {
  name: 'PanelDescription',
  components: { IndicatorHeader },
  data() {
    return {
      INDICATOR_TYPES
    }
  }
}
</script>
