<template>
  <v-row class="emptyInstant">
    <v-col cols="12" align="left">
      <InstantDateSelector @change="onDateChange" :date="date" />
    </v-col>
    <v-col cols="12" md="6">
      <BaseSimpleSmallIndicator score="XX/100" title="Mon skor" :isBig="true" />
    </v-col>
    <v-col cols="12" md="6" class="pt-0 px-md-3 px-0">
      <v-row>
        <v-col cols="6">
          <BaseSimpleSmallIndicator
            score="XX/100"
            title="Mon meilleur indicateur"
            :isBig="false"
          />
        </v-col>
        <v-col cols="6">
          <BaseSimpleSmallIndicator
            score="XX/100"
            title="Mon moins bon indicateur"
            :isBig="false"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <BaseSimpleSmallIndicator
            score="XX/100"
            title="Skor de mon secteur"
            :isBig="false"
          />
        </v-col>
        <v-col cols="6">
          <BaseSimpleSmallIndicator
            score="XX/100"
            title="Skor du meilleur site de mon secteur"
            :isBig="false"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" align="center" class="px-10 my-10 instantIntermediary">
      <p class="mb-6">
        Vous souhaitez en savoir plus sur votre web performance ? Analysez
        l'évolution de la web performance de votre site et de votre secteur
        concurrentiel en demandant votre rapport détaillé sur une période de 28
        jours.
      </p>

      <SwitchingButton
        primaryText="J'obtiens mon rapport"
        primaryColor="reveBleu"
        secondaryText="J'obtiens mon rapport"
        secondaryColor="vieEnRose"
        @click="() => getReport()"
      />
    </v-col>
  </v-row>
</template>

<script>
import BaseSimpleSmallIndicator from '@/components/barometer/smallIndicator/simple/BaseSimpleSmallIndicator.vue';
import InstantDateSelector from '@/components/instant/DateSelector.vue';
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { ROUTE_INSCRIPTION } from '@/router';

export default {
  name: 'EmptyInstant',
  components: {
    InstantDateSelector,
    SwitchingButton,
    BaseSimpleSmallIndicator,
  },
  props: {
    date: { type: String },
  },
  methods: {
    onDateChange(date) {
      this.$emit('dateChange', date);
    },
    getReport() {
      this.$router.push({ name: ROUTE_INSCRIPTION });
    },
  },
};
</script>
