<template>
  <div id="app-homepage">
    <div class="big-panel vcenter-item pin" data-duration="20%">
      <div class="title-container">
        <h1 class="skor text-center">
          <div class="random-move">S</div>
          <div class="random-move">K</div>
          <div class="random-move">O</div>
          <div class="random-move">R</div>
        </h1>
        <div class="skor animation animated" data-animation="moveUp" data-duration="500" data-config="moveTitleUp" data-trigger="1" data-triggerElement="#siteTestForm">
          <div class="skor-color">
            <img
                class="random-move skor-color-letter skor-color-letter-s1"
                src="../assets/images/S-logo1.png"
                alt="S"
            />
            <img
                class="random-move skor-color-letter skor-color-letter-s2"
                src="../assets/images/S-logo2.png"
                alt="S"
            />
            <img
                class="random-move skor-color-letter skor-color-letter-k"
                src="../assets/images/K-logo.png"
                alt="K"
            />
            <img
                class="random-move skor-color-letter skor-color-letter-o"
                src="../assets/images/O-logo.png"
                alt="O"
            />
            <img
                class="random-move skor-color-letter skor-color-letter-r"
                src="../assets/images/R-logo.png"
                alt="R"
            />
          </div>
        </div>
      </div>
      <ScrollHint />
    </div>

    <HeaderToggler :scrollController="scrollController" />
    <SiteTestForm />

    <PanelDescription />

    <LayeredStack :scrollController="scrollController" />

    <AutoScrollingTextLoop
      :duration="15"
      text="L'outil SKOR est accessible à tous et son utilisation ne recquiert aucune connaissance technique."
    />

    <DataBases />
  </div>
</template>

<script>
import AutoScrollingTextLoop from '@/components/AutoScrollingTextLoop.vue';
import HeaderToggler from '@/components/header/HeaderToggler.vue';
import DataBases from '@/components/home/DataBases.vue';
import LayeredStack from '@/components/home/LayeredStack.vue';
import PanelDescription from '@/components/home/PanelDescription.vue';
import SiteTestForm from '@/components/home/SiteTestForm.vue';
import ScrollHint from '@/components/ScrollHint.vue';

import anime from 'animejs/lib/anime.es.js';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

export default {
  components: {
    AutoScrollingTextLoop,
    ScrollHint,
    HeaderToggler,
    LayeredStack,
    PanelDescription,
    SiteTestForm,
    DataBases,
  },
  data() {
    return {
      configs: {},
      chainedAnimations: {},
      scrollController: new ScrollMagic.Controller(),
      animations: {},
    };
  },
  methods: {
    reset() {
      this.configs = {};
      this.chainedAnimations = {};
    },
    addAnimationConfig(name, config) {
      this.configs[name] = config;
    },

    moveLeft(element, configName, autoPlay) {
      if (window.innerWidth > 769) {
        return anime({
          targets: element,
          easing: 'easeInQuad',
          translateX: [0, '-115%'],
          duration: 1200,
          autoplay: autoPlay,
        });
      }
      return anime({
        targets: element,
        easing: 'easeInQuad',
        translateX: [0, '-100%'],
        duration: 1000,
        autoplay: autoPlay,
      });
    },

    moveUp(element, configName, autoPlay) {
      const power = configName && this.configs[configName].power
          ? `-${this.configs[configName].power}%`
          : '-100%';
      return anime({
        targets: element,
        easing: 'easeInQuad',
        translateY: [0, power],
        duration: 1000,
        autoplay: autoPlay,
      });
    },

    hide(element, configName, autoPlay) {
      return anime({
        targets: element,
        easing: 'easeInQuad',
        opacity: 0,
        duration: 1000,
        autoplay: autoPlay,
      });
    },

    counter(element, configName, autoPlay) {
      return anime({
        targets: element,
        easing: 'easeInQuad',
        value: [0, $(element).data('to')],
        round: 1,
        duration: 1000,
        autoplay: autoPlay,
      });
    },

    animateWithConfig(element, config, reverse) {
      var completeConfig = Object.assign({}, config.element);
      completeConfig.targets = element;
      completeConfig.easing = 'easeInQuad';
      completeConfig.duration = 500;
      completeConfig.autoplay = true;
      // https://tobiasahlin.com/moving-letters/#9
      if (reverse === true) {
        completeConfig.direction = 'reverse';
      }
      anime(completeConfig);

      if (config.children && Object.keys(config.children).length > 0) {
        Object.keys(config.children).forEach((key) => {
          this.animateWithConfig($(element).find(key)[0], config.children[key], reverse);
        });
      }

      if (config.parent) {
        this.animateWithConfig($(element).parent()[0], config.parent, reverse);
      }
    },
  },
  created() {
    this.scrollController = new ScrollMagic.Controller();
    this.animations = {
      moveLeft: this.moveLeft,
      moveUp: this.moveUp,
      hide: this.hide,
      counter: this.counter,
    };

    var scripts = [
      'https://cdnjs.cloudflare.com/ajax/libs/animejs/3.2.1/anime.min.js',
    ];
    scripts.forEach((script) => {
      let tag = document.createElement('script');
      tag.setAttribute('src', script);
      document.head.appendChild(tag);
    });
  },
  mounted() {
    this.reset();

    this.addAnimationConfig('changeBackGroundToRed', {
      rgba: '#ff0000',
    });
    this.addAnimationConfig('changeBackGroundToGreen', {
      color: '#00ff00',
    });
    this.addAnimationConfig('changeBackGroundToBlue', {
      color: '#0000ff',
    });
    this.addAnimationConfig('moveTitleUp', {
      power: 1000,
    });

    setTimeout(() => {
      $('.animated').each((id, element) => {
        var animationName = $(element).data('animation');
        var configName = $(element).data('config');
        var duration = $(element).data('duration');
        var triggerElement = $(element).data('triggerelement');
        var trigger = parseFloat($(element).data('trigger'));
        if ($(element).data('play')) {
          var play = JSON.parse($(element).data('play'));
        }
        if (!play) {
          play = false;
        }
        var animation = this.animations[animationName](element, configName, false);

        if (!trigger && trigger !== 0) {
          trigger = 1;
        }

        if (!triggerElement) {
          triggerElement = element;
        }

        if (!duration) {
          duration = '100%';
        }

        var scene = new ScrollMagic.Scene({
          triggerElement: triggerElement,
          duration: duration,
          triggerHook: trigger,
        });

        if (!play) {
          scene.on('progress', (scroll) => {
            const seekValue = 1000 * scroll.progress;
            animation.seek(seekValue);
          });
        } else {
          scene.on('enter', () => {
            animation.play();
          });
        }

        scene.addTo(this.scrollController);
      });

      $('.pin').each((id, element) => {
        const duration = $(element).data('duration') || '200%';

        new ScrollMagic.Scene({
          triggerElement: element,
          duration: duration,
          triggerHook: 0,
        })
          .setPin(element)
          .addTo(this.scrollController);
      });
    }, 500);
  },
};
</script>
