<template>
  <div class="scrollingTextContainerLoop">
    <div class="scrollingTextSubContainer">
      <div class="scrollingTextContent" :style="contentStyle">{{ text }}</div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AutoScrollingTextLoop',
  props: {
    text: String,
    duration: Number,
  },
  computed: {
    contentStyle() {
      if (!this.duration) {
        return {};
      }
      return { 'animation-duration': `${this.duration}s` };
    },
  },
};
</script>
