import '@fortawesome/fontawesome-free/css/all.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import { INDICATORS_LIST } from '@/modules/config'

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa',
  },
  theme: {
    dark: false,
    light: true,
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#05204A',
        blueVelvet: '#05204A',
        soleilEte: '#FFB501',
        reveBleu: '#01BEFE',
        cactusDudu: '#99CC00',
        purpleRain: '#B21DFF',
        vieEnRose: '#FF006D',
        error: '#E8100C',
        ...INDICATORS_LIST.reduce(
          (acc, iter) => ({
            ...acc,
            [iter.key + '-primary']: iter.color.primary,
            [iter.key + '-secondary']: iter.color.secondary
          }),
          {}
        )
      },
      dark: {
        primary: '#0A699C',
        primaryDark: '#0D1A26',
        secondary: '#08486A',
        tertiary: '#166A9E',
        quaternary: '#0F486B',
        accent: '#A05C12',
        success: '#00DB9E',
        error: '#A9453B',
        background: '#003451',
        brandYellow: '#F9CA05',
        ...INDICATORS_LIST.reduce(
          (acc, iter) => ({
            ...acc,
            [iter.key + '-primary']: iter.color.primary,
            [iter.key + '-secondary']: iter.color.secondary
          }),
          {}
        )
      }
    }
  }
})
