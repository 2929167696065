<template>
  <v-dialog v-model="showDescription" width="700">
    <template v-slot:activator="{ on, attrs }">
      <span class="icon" v-bind="attrs" v-on="on"></span>
    </template>

    <v-card class="pa-6">
      <v-card-title class="justify-center px-0">
        <div class="barometerIndicatorHeader" :class="`barometerIndicatorHeader${type.toUpperCase()}`">
          <img
            v-if="image"
            class="image"
            :src="image.src"
            :width="image.width"
            :height="image.height"
            :alt="title"
          />
          <div class="title-part">
            <div class="pre-title" :class="colorName + '--text'">
              {{ preTitle }}
            </div>
            <p class="header-title">{{ title }}</p>
          </div>
        </div>
      </v-card-title>

      <div class="absolute-close-btn">
        <v-btn icon close @click="showDescription = false"
          ><v-icon>close</v-icon></v-btn
        >
      </div>

      <v-card-text class="barometerIndicatorHeaderModalText">
        <div>
          <p :class="colorName + '--text-darken'" class="description">
            {{ description }}
          </p>
          <br />
          <p class="callToActionText">{{ $t('Indicator.callToActionText') }}</p>
        </div>
      </v-card-text>

      <v-card-actions class="justify-center">
        <SwitchingButton
          large
          width="300"
          primaryText="J'obtiens mon rapport"
          secondaryText="J'obtiens mon rapport"
          :primaryColor="colorName"
          :secondaryColor="getButtonSecondaryColor(colorName)"
          @click="onClick"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  INDICATOR_COLORS,
  INDICATOR_TYPES,
  INDICATORS_LIST,
} from '@/modules/config';
import { ROUTE_INSCRIPTION } from '@/router';
import SwitchingButton from '../../utils/SwitchingButton.vue';

export default {
  name: 'BarometerIndicatorInfoModal',
  props: {
    type: String,
    title: String,
    preTitle: String,
    image: Object,
  },
  data() {
    return {
      showDescription: false,
      INDICATOR_TYPES,
    };
  },
  methods: {
    onClick() {
      this.showDescription = false;
      this.$router.push({ name: ROUTE_INSCRIPTION });
    },
    getButtonSecondaryColor(colorName) {
      switch (colorName) {
        case 'purpleRain':
          return 'cactusDudu';
        case 'soleilEte':
          return 'purpleRain';
        case 'reveBleu':
          return 'vieEnRose';
        case 'vieEnRose':
          return 'soleilEte';
        case 'cactusDudu':
          return 'vieEnRose';
      }
    },
  },
  computed: {
    colorName() {
      return INDICATOR_COLORS[this.type].name;
    },
    sourceLink() {
      const indicator = INDICATORS_LIST.find((i) => i.key === this.type);
      return indicator ? indicator.sourceLink : null;
    },
    description() {
      return this.$t('Indicators.' + this.type + '.description');
    },
  },
  components: { SwitchingButton },
};
</script>
