export const URL_RULES = [
  (v) => {
    if (v === '') {
      return "L'URL ne doit pas être vide";
    }
    if (!v.startsWith('http://') && !v.startsWith('https://')) {
      return "L'URL doit commencer par http:// ou https://";
    }
    return true;
  },
];
