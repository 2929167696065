<template>
  <!--  Colors  -->
  <v-row>
    <v-col cols="12">
      <h2>
        Nos couleurs
        <span>! Le chiffre d'or</span>
      </h2>
    </v-col>

    <v-col cols="2" v-for="color in colors" :key="color.color">
      <v-card align="center">
        <div class="colorSquare" :class="`${color.color}--bg`"></div>
        <v-card-title>{{ color.title }}</v-card-title>
        <v-card-subtitle>{{ color.subtitle }}</v-card-subtitle>
        <v-card-text>{{ color.text }}</v-card-text>
      </v-card>
    </v-col>

    <!--  Color Combinations  -->
    <v-col cols="12">
      <h2>Nos combinaisons de couleurs</h2>
    </v-col>

    <v-col cols="12">
      <h2 class="vieEnRose--text">Les duos de chox</h2>
      <v-row>
        <v-col v-for="(combination, i) in combinations.duoChoc" :key="i">
          <div
            class="colorSquare"
            v-for="color in combination"
            :key="color"
            :class="`${color}--bg`"
          ></div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <h2 class="purpleRain--text">Trois mon trois</h2>
      <v-row>
        <v-col v-for="(combination, i) in combinations.troisMonTrois" :key="i">
          <div
            class="colorSquare"
            v-for="color in combination"
            :key="color"
            :class="`${color}--bg`"
          ></div>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <h2 class="cactusDudu--text">Ton sur Ton</h2>
      <v-row>
        <v-col v-for="color in colors" :key="color.color">
          <div class="colorSquare" :class="`${color.color}--bg`"></div>
          <div class="colorSquare" :class="`${color.color}--bg-darken`"></div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AppStyleGuideColors',
  data() {
    return {
      colors: [
        {
          title: 'Blue Velvet',
          subtitle: '#05204A',
          text: 'Titrages, textes courants. Cette couleur est à coupler avec une des 5 autres couleurs principales.',
          color: 'blueVelvet'
        },
        {
          title: "Soleil d'été",
          subtitle: '#FFB501',
          text: 'Ces couleurs sont à utiliser pour des aplats de couleurs, des boutons, pour mettre en évidence un texte ou des sous-titres.',
          color: 'soleilEte'
        },
        {
          title: 'Un rêve bleu',
          subtitle: '#01BEFE',
          text: 'Ces couleurs sont à utiliser pour des aplats de couleurs, des boutons, pour mettre en évidence un texte ou des sous-titres.',
          color: 'reveBleu'
        },
        {
          title: 'Le cactus à dudu',
          subtitle: '#99CC00',
          text: 'Ces couleurs sont à utiliser pour des aplats de couleurs, des boutons, pour mettre en évidence un texte ou des sous-titres.',
          color: 'cactusDudu'
        },
        {
          title: 'Purple rain',
          subtitle: '#B21DFF',
          text: 'Ces couleurs sont à utiliser pour des aplats de couleurs, des boutons, pour mettre en évidence un texte ou des sous-titres.',
          color: 'purpleRain'
        },
        {
          title: 'La vie en rose',
          subtitle: '#FF006D',
          text: 'Ces couleurs sont à utiliser pour des aplats de couleurs, des boutons, pour mettre en évidence un texte ou des sous-titres.',
          color: 'vieEnRose'
        }
      ],
      combinations: {
        duoChoc: [
          ['cactusDudu', 'vieEnRose'],
          ['purpleRain', 'soleilEte'],
          ['reveBleu', 'cactusDudu'],
          ['reveBleu', 'soleilEte'],
          ['soleilEte', 'vieEnRose'],
          ['reveBleu', 'vieEnRose'],
          ['purpleRain', 'cactusDudu']
        ],
        // blueVelvet soleilEte reveBleu cactusDudu purpleRain vieEnRose
        troisMonTrois: [
          ['vieEnRose', 'soleilEte', 'cactusDudu'],
          ['soleilEte', 'purpleRain', 'cactusDudu'],
          ['vieEnRose', 'reveBleu', 'cactusDudu'],
          ['soleilEte', 'purpleRain', 'reveBleu'],
          ['vieEnRose', 'purpleRain', 'reveBleu']
        ],
        tonSurTon: ['cactusDudu']
      }
    }
  }
}
</script>

<style scoped="scoped">
.colorSquare {
  width: 64px;
  height: 64px;
  display: inline-block;
}
</style>
