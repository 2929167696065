<template>
  <Select
    label=""
    v-model="value"
    :value="value"
    :items="dates"
    className="d-inline-block instantDateSelector"
  />
</template>

<script>
import Select from '@/components/utils/Select.vue';

export default {
  name: 'InstantDateSelector',
  components: { Select },
  props: {
    date: { type: String },
  },
  data() {
    return {
      value: this.date,
      dates: [
        {
          text: this.$t('Instant.Form.Date.today'),
          value: 'today',
        },
        {
          text: this.$t('Instant.Form.Date.average7'),
          value: '7days',
        },
        {
          text: this.$t('Instant.Form.Date.average28'),
          value: '28days',
        },
      ],
    };
  },
  watch: {
    value() {
      this.$emit('change', this.value);
    },
    date() {
      this.value = this.date;
    },
  },
  mounted() {
    this.value = this.date;
  },
};
</script>
