<template>
  <v-toolbar min-height="56px" height="auto" color="primaryDark" flat>
    <v-row justify="space-between">
      <v-toolbar-title class="toolbar-title font-italic font-weight-medium">
        <slot>{{ title }}</slot>
      </v-toolbar-title>
      <v-col v-if="subtitle" class="text-right font-italic font-weight-light">
        {{ subtitle }}
      </v-col>
    </v-row>
  </v-toolbar>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false, default: '' },
  },
};
</script>

<style scoped>
.v-toolbar__title.toolbar-title {
  white-space: initial;
  padding: 0.5rem;
}
</style>
