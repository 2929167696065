<template>
  <v-card v-if="valid" :class="extraClass" class="barometerIndicator">
    <v-card-title>
      <Header :type="type" />
    </v-card-title>
    <v-card-text>
      <Mark :type="type" :score="score" />
      <canvas class="canvas" :id="canvasId" ref="canvas"></canvas>
      <v-row class="legend">
        <BarometerIndicatorLegend
          :cols="4"
          :color="averageHomeDataSet.borderColor"
          :label="averageHomeDataSet.label"
        />
        <BarometerIndicatorLegend
          :cols="4"
          :color="averageFicheDataSet.borderColor"
          :label="averageFicheDataSet.label"
        />
        <BarometerIndicatorLegend
          :cols="4"
          :color="averageListingDataSet.borderColor"
          :label="averageListingDataSet.label"
        />
        <BarometerIndicatorLegend
          :cols="isInstant ? 4 : 6"
          :color="historyDataSet.borderColor"
          :label="historyDataSet.label"
        />
        <BarometerIndicatorLegend
          :cols="isInstant ? 4 : 6"
          :color="globalScoreDataSet.borderColor"
          :label="globalScoreDataSet.label"
        />
        <BarometerIndicatorLegend
          v-if="isInstant"
          :cols="4"
          :color="websiteDataSet.borderColor"
          :label="websiteDataSet.label"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BarometerIndicatorLegend from '@/components/barometer/indicator/Legend.vue'
import {
  Chart,
  LineController,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  Tooltip
} from 'chart.js'
import Header from '@/components/barometer/indicator/Header.vue'
import Mark from '@/components/barometer/indicator/Mark.vue'
import { INDICATOR_TYPES, INDICATOR_COLORS, URL_TYPES } from '@/modules/config'
import indicatorUtils from '@/utils/barometer/formatters'

// We need to register EVERY element from Chart to use it
Chart.register({
  LineController,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  Tooltip
})
export default {
  name: 'BarometerIndicator',
  components: { BarometerIndicatorLegend, Header, Mark },
  props: {
    type: String,
    barometer: Object,
    isInstant: Boolean
  },
  data() {
    return {
      chart: null,
      pointNumber: 6, // 7 day history
      colors: {
        averageHome: '#006080',
        averageFiche: '#003040',
        averageList: '#0099cc',
        sector: INDICATOR_COLORS.sector.primary,
        site: INDICATOR_COLORS[this.type].primary,
        grid: '#bbbbbb'
      },
      tension: 0.5
    }
  },
  computed: {
    canvasId() {
      return `indicator-canva-${this.type}`
    },
    valid() {
      return Object.values(INDICATOR_TYPES).includes(this.type)
    },
    extraClass() {
      switch (this.type) {
        case INDICATOR_TYPES.LCP:
          return 'indicatorLCP'
        case INDICATOR_TYPES.FCP:
          return 'indicatorFCP'
        case INDICATOR_TYPES.TBT:
          return 'indicatorTBT'
        case INDICATOR_TYPES.CLS:
          return 'indicatorCLS'
        case INDICATOR_TYPES.SI:
          return 'indicatorSI'
      }
      return ''
    },
    score() {
      if (!this.isInstant) {
        return this.barometer.scores[`score_${this.type}`]['7days']
      }
      return this.barometer.instant[`score_${this.type}`].instant
    },
    websiteDataSet() {
      if (!this.isInstant) {
        return null
      }
      const values = indicatorUtils.toXPoints({
        points: [],
        pointNumber: this.pointNumber,
        defaultValue: this.barometer.instant[`score_${this.type}`].instant
      })
      return {
        label: `note ${this.type} du site`,
        fill: false,
        borderColor: INDICATOR_COLORS[this.type].secondary,
        backgroundColor: INDICATOR_COLORS[this.type].secondary,
        tension: this.tension,
        data: values,
        borderWidth: 3
      }
    },
    historyDataSet() {
      // sector score on 7 last days
      const history = indicatorUtils.getScoreHistory({
        barometer: this.barometer,
        type: this.type,
        pointNumber: this.pointNumber
      })
      return {
        label: `historique ${this.type} du secteur`,
        fill: false,
        borderColor: INDICATOR_COLORS[this.type].primary,
        backgroundColor: INDICATOR_COLORS[this.type].primary,
        tension: this.tension,
        data: history,
        borderWidth: this.isInstant ? 1 : 3
      }
    },
    averageHomeDataSet() {
      const homeAverage = indicatorUtils.getAverageScoreForUrlType({
        barometer: this.barometer,
        type: this.type,
        urlType: URL_TYPES.HOME,
        pointNumber: this.pointNumber
      })
      // sector score on home pages
      const values = Array.from(Array(this.pointNumber)).map(() => homeAverage)
      return {
        label: `moyenne ${this.type} des pages d'accueil du secteur`,
        fill: false,
        borderColor: this.colors.averageHome,
        backgroundColor: this.colors.averageHome,
        tension: this.tension,
        data: values,
        borderWidth: 1
      }
    },
    averageListingDataSet() {
      // Sector score on product listing pages
      const listingAverage = indicatorUtils.getAverageScoreForUrlType({
        barometer: this.barometer,
        type: this.type,
        urlType: URL_TYPES.LISTING,
        pointNumber: this.pointNumber
      })
      const values = Array.from(Array(this.pointNumber)).map(
        () => listingAverage
      )
      return {
        label: `moyenne ${this.type} des pages listing du secteur`,
        fill: false,
        borderColor: this.colors.averageList,
        backgroundColor: this.colors.averageList,
        tension: this.tension,
        data: values,
        borderWidth: 1
      }
    },
    averageFicheDataSet() {
      // Sector score on product page
      const ficheAverage = indicatorUtils.getAverageScoreForUrlType({
        barometer: this.barometer,
        type: this.type,
        urlType: URL_TYPES.PRODUCT,
        pointNumber: this.pointNumber
      })
      const values = Array.from(Array(this.pointNumber)).map(() => ficheAverage)
      return {
        label: `moyenne ${this.type} des pages produit du secteur`,
        fill: false,
        borderColor: this.colors.averageFiche,
        backgroundColor: this.colors.averageFiche,
        tension: this.tension,
        data: values,
        borderWidth: 1
      }
    },
    globalScoreDataSet() {
      // Global Score
      const values = indicatorUtils.getGlobalScore({
        barometer: this.barometer,
        type: this.type,
        pointNumber: this.pointNumber
      })
      return {
        label: `Score ${this.type} de tous les secteurs`,
        fill: false,
        borderColor: this.colors.sector,
        backgroundColor: this.colors.sector,
        tension: this.tension,
        data: values,
        borderWidth: 1
      }
    },
    datasets() {
      const datasets = [
        this.historyDataSet,
        this.averageHomeDataSet,
        this.averageFicheDataSet,
        this.averageListingDataSet,
        this.globalScoreDataSet
      ]
      if (this.isInstant) {
        datasets.push(this.websiteDataSet)
      }
      return datasets
    },
  },
  methods: {
    createChart() {
      const canvas = document.getElementById(this.canvasId)
      const config = {
        type: 'line',
        data: {
          labels: Array.from(Array(this.pointNumber).keys()).map(
            (element) => element + 1
          ),
          datasets: this.datasets
        },
        options: {
          // maintainAspectRatio: false,
          elements: {
            point: {
              radius: 0
            }
          },
          plugins: {
            tooltip: {
              enabled: true,
              callbacks: {
                title: function (tooltipItems) {
                  const n = this.pointNumber - tooltipItems[0].dataIndex
                  return `Il y a ${n} jours` // TODO
                }.bind(this)
              }
            }
          },
          interaction: {
            mode: 'index',
            axis: 'x',
            intersect: false
          },
          scales: {
            x: {
              grid: {
                borderColor: this.colors.grid,
                color: this.colors.grid
              },
              ticks: {
                display: false
              }
            },
            y: {
              grid: {
                borderColor: this.colors.grid,
                color: this.colors.grid
              },
              ticks: {
                color: this.colors.grid,
                count: 7
              }
            }
          }
        }
      }
      this.chart = new Chart(canvas, config)
    },
    destroyChart() {
      if (this.chart) {
        this.chart.destroy()
        this.chart = null
      }
    }
  },
  async mounted() {
    this.destroyChart()
    if (!this.valid) {
      return
    }
    this.createChart()
  },
  beforeDestroy() {
    this.destroyChart()
  }
}
</script>
