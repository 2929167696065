<template>
  <BaseSimpleSmallIndicator
      :score="`${diff}/100`"
      title="Skor du meilleur site de mon secteur"
      :isBig="isBig"
  />
</template>

<script>
import BaseSimpleSmallIndicator from '@/components/barometer/smallIndicator/simple/BaseSimpleSmallIndicator.vue';

export default {
  name: 'SimpleSmallIndicatorBestSkor',
  components: { BaseSimpleSmallIndicator },
  props: {
    instant: { type: Object, required: true },
    isBig: { type: Boolean },
  },
  computed: {
    diff() {
      return parseInt(this.instant.winner.score);
    },
  },
};
</script>
