<template>
  <div class="layeredStack big-panel" ref="container">
    <v-container>
      <div class="layeredTitles" v-if="!isMobile">
        <h2 class="layeredTitle" ref="title1" :class="getTitleClass(1)">
          Simple.
        </h2>
        <h2 class="layeredTitle" ref="title2" :class="getTitleClass(2)">
          Gratuit.
        </h2>
        <h2 class="layeredTitle" ref="title3" :class="getTitleClass(3)">
          SEO friendly.
        </h2>
      </div>

      <div class="layeredContent big-panel" ref="content1" :class="getContentClass(1)">
        <div class="content align-items-center text-center">
          <h2 class="layeredTitleMobile" v-if="isMobile">Simple.</h2>
          <div class="letters font-size-4">
            <div v-for="(word, wordIndex) in txt1"  :key="'w1-' + wordIndex" class="word">
              <span v-for="(char, charIndex) in word" :key="'c1-' + charIndex" class="letter">{{ char }}</span>
            </div>
          </div>
          <SwitchingButton
              :primaryText="$t('Home.LayeredStack.obtainMySkor')"
              primaryColor="cactusDudu"
              :secondaryText="$t('Home.LayeredStack.obtainMySkor')"
              secondaryColor="soleilEte"
              href="#siteTestForm"
          />
        </div>
      </div>

      <div class="layeredContent big-panel" ref="content2" :class="getContentClass(2)">
        <div class="content align-items-center text-center">
          <h2 class="layeredTitleMobile" v-if="isMobile">Gratuit.</h2>
          <div class="letters font-size-4">
            <div v-for="(word, wordIndex) in txt2"  :key="`w2-${wordIndex}`" class="word">
              <span v-for="(char, charIndex) in word" :key="`c2-${charIndex}`" class="letter">{{ char }}</span>
            </div>
          </div>
          <SwitchingButton
              :primaryText="$t('Home.LayeredStack.obtainMySkor')"
              primaryColor="soleilEte"
              :secondaryText="$t('Home.LayeredStack.obtainMySkor')"
              secondaryColor="cactusDudu"
              href="#siteTestForm"
          />
        </div>
      </div>

      <div class="layeredContent big-panel" ref="content3" :class="getContentClass(3)">
        <div class="content align-items-center text-center">
          <h2 class="layeredTitleMobile" v-if="isMobile">SEO friendly.</h2>
          <div class="letters font-size-4">
            <div v-for="(word, wordIndex) in txt3"  :key="`w3-${wordIndex}`" class="word">
              <span v-for="(char, charIndex) in word" :key="`c3-${charIndex}`" class="letter">{{ char }}</span>
            </div>
          </div>
          <SwitchingButton
              :primaryText="$t('Home.LayeredStack.obtainMySkor')"
              primaryColor="reveBleu"
              :secondaryText="$t('Home.LayeredStack.obtainMySkor')"
              secondaryColor="vieEnRose"
              href="#siteTestForm"
          />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import anime from 'animejs/lib/anime.es.js';
import $ from 'jquery';
import ScrollMagic from 'scrollmagic';

export default {
  name: 'LayeredStack',
  components: { SwitchingButton },
  props: {
    scrollController: Object,
  },
  data() {
    return {
      scene: null,
      step: 0,
      txt1: this.$t('Home.LayeredStack.text1').split(' '),
      txt2: this.$t('Home.LayeredStack.text2').split(' '),
      txt3: this.$t('Home.LayeredStack.text3').split(' '),
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  methods: {
    initScroll() {
      const element = this.$refs.container;
      this.scene = new ScrollMagic.Scene({
        triggerElement: element,
        duration: '300%',
        triggerHook: 0,
      })
        .on('progress', (scroll) => {
          const seekValue = scroll.progress;
          const childrenCount = $(element).find('.layeredContent').length;
          const stepSize = 1 / childrenCount;
          const newStep = Math.ceil(seekValue / stepSize);

          this.setStep(newStep);
        })
        .setPin(element)
        .addTo(this.scrollController);
    },
    setStep(newStep) {
      if (newStep > 0 && newStep === this.step) {
        return;
      }

      this.animateBackgroundColor(newStep);
      this.animateTitleBlock(newStep);
      this.animateContent(newStep);

      this.step = newStep;
    },
    animateBackgroundColor(newStep) {
      let backgroundColor = '#ffffff';
      switch (newStep) {
        case 3:
          backgroundColor = 'rgba(153, 204, 0, 1)';
          break;
        case 2:
          backgroundColor = 'rgba(178, 77, 255, 1)';
          break;
        case 1:
        default:
          backgroundColor = 'rgba(255, 0, 109, 1)';
          break;
      }
      anime({
        targets: this.$refs.container,
        duration: 500,
        easing: 'easeInQuad',
        autoplay: true,
        backgroundColor,
      }, 500);
    },
    animateContent(newStep) {
      let activeLayer = [];
      let passiveLayer = [];
      switch (newStep) {
        case 3:
          activeLayer = [this.$refs.content3];
          passiveLayer = [this.$refs.content2, this.$refs.content1];
          break;
        case 2:
          activeLayer = [this.$refs.content2];
          passiveLayer = [this.$refs.content3, this.$refs.content1];
          break;
        case 1:
          activeLayer = [this.$refs.content1];
          passiveLayer = [this.$refs.content3, this.$refs.content2];
          break;
        default:
          activeLayer = [];
          passiveLayer = [this.$refs.content3, this.$refs.content2, this.$refs.content1];
          break;
      }

      // Hide passive layers
      anime({
        targets: passiveLayer,
        duration: 500,
        easing: "easeInElastic(1, .6)",
        autoplay: true,
        opacity: 0,
        zIndex: 1,
      });
      // Hide passive letters
      anime({
        targets: [...passiveLayer.map(l => l.querySelectorAll('.letter'))],
        easing: "easeInOutQuad",
        duration: 500,
        opacity: 0,
      });
      anime({
        targets: [...passiveLayer.map(l => l.querySelectorAll('.letters'))],
        easing: "easeInOutQuad",
        duration: 500,
        translateY: [0, 40],
      });
      // Hide passive buttons
      anime({
        targets: [...passiveLayer.map(l => l.querySelectorAll('.v-btn'))],
        easing: "easeOutExpo",
        duration: 50,
        opacity: [1, 0],
        translateY: 20,
      });
      // Hide passive mobile titles
      anime({
        targets: [...passiveLayer.map(l => l.querySelectorAll('.layeredTitleMobile'))],
        easing: "easeOutExpo",
        duration: 500,
        opacity: 0,
      });
      // Show active layer
      if (activeLayer.length) {
        anime({
          targets: activeLayer,
          duration: 500,
          easing: "easeInElastic(1, .6)",
          autoplay: true,
          opacity: 1,
          zIndex: 2,
        });
        // Move letters
        anime({
          // targets: activeLayer[0].querySelectorAll('.word'),
          targets: activeLayer[0].querySelectorAll('.letter'),
          easing: "easeInOutQuad",
          duration: 400,
          delay: (el, i) => 100 + (6 * i),
          translateY: [20, 0],
          translateZ: 0,
          opacity: [0, 1],
        });
        anime({
          targets: activeLayer[0].querySelectorAll('.letters'),
          easing: "easeInOutQuad",
          duration:400,
          delay: 400,
          translateY: [40, 0],
        });
        // Show buttons
        anime({
          targets: activeLayer[0].querySelectorAll('.v-btn'),
          easing: "linear",
          duration: 600,
          opacity: [0, 1],
        });
        anime({
          targets: activeLayer[0].querySelectorAll('.v-btn'),
          easing: "easeOutExpo",
          duration: 500,
          translateY: [40, 0],
        });
        // Show mobile titles
        if (this.isMobile) {
          anime({
            targets: activeLayer[0].querySelectorAll('.layeredTitleMobile'),
            easing: "easeOutExpo",
            duration: 500,
            opacity: 1,
          });
        }
      }
    },
    animateTitleBlock(newStep) {
      if (this.isMobile) {
        return;
      }
      if (window.innerWidth > 769) {
        let titleTop = '0%';
        switch (newStep) {
          case 3:
            titleTop = '-66%';
            break;
          case 2:
            titleTop = '-33%';
            break;
          case 1:
          default:
            titleTop = '0%';
            break;
        }
        const titleBlockAnime = {
          targets: ['.layeredTitles'],
          duration: 500,
          easing: 'easeInQuad',
          autoplay: true,
          top: titleTop,
          left: '0%',
        };
        anime(titleBlockAnime, 500);
        return;
      }
      const baseConf = {
        duration: 500,
        easing: 'easeInQuad',
        autoplay: true,
        top: '0%',
      };

      let lefts = {
        preTitle3: '0%',
        title1: '50%',
        title2: '100%',
        title3: '150%',
        postTitle1: '150%',
      };
      switch (newStep) {
        case 3:
          lefts = {
            preTitle3: '-100%',
            title1: '-100%',
            title2: '0%',
            title3: '50%',
            postTitle1: '100%',
          };
          break;
        case 2:
          lefts = {
            preTitle3: '-100%',
            title1: '0%',
            title2: '50%',
            title3: '100%',
            postTitle1: '150%',
          };
          break;
        case 1:
        default:
          lefts = {
            preTitle3: '0%',
            title1: '50%',
            title2: '100%',
            title3: '150%',
            postTitle1: '150%',
          };
          break;
      };
      anime({
        targets: this.$refs.preTitle3,
        ...baseConf,
        left: lefts.preTitle3,
      }, 500);
      anime({
        targets: this.$refs.title1,
        ...baseConf,
        left: lefts.title1,
      }, 500);
      anime({
        targets: this.$refs.title2,
        ...baseConf,
        left: lefts.title2,
      }, 500);
      anime({
        targets: this.$refs.title3,
        ...baseConf,
        left: lefts.title3,
      }, 500);
      anime({
        targets: this.$refs.postTitle1,
        ...baseConf,
        left: lefts.postTitle1,
      }, 500);
    },
    getTitleClass(concernedStep) {
      if (concernedStep === this.step) {
        return 'layeredTitleActive';
      }
      return 'layeredTitlePassive';
    },
    getContentClass(concernedStep) {
      if (concernedStep === this.step) {
        return 'layeredContentActive';
      }
      return 'layeredContentPassive';
    },
  },
  mounted() {
    this.initScroll();
    this.setStep(0);
  },
  beforeDestroy() {
    this.scene.remove();
  },
};
</script>
