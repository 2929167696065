<template>
  <v-container class="centered-container">
    <v-row>
      <v-col>
        <h1 class="vieEnRose--text">
          {{ $t('CGU.header') }}
        </h1>
        <div v-html="$t('CGU.content')" class="primary--text"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CGU',
};
</script>
