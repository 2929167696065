export const BASE_URL = 'https://' + process.env.VUE_APP_API_DOMAIN

export const BAROMETER_ENDPOINT = '/barometer'
export const SECTORS_ENDPOINT = '/sectorList'
export const DATASET_ENDPOINT = '/dataset'
export const URL_CHECK_ENDPOINT = '/urlcheck'
export const INSTANT_ENDPOINT = '/instant' // eslint-disable-line

export const getBarometer = (sector_id, strategy) =>
  getter(BAROMETER_ENDPOINT, { sector_id: parseInt(sector_id), strategy })

export const getInstant = (sector_id, strategy, url) =>
  getter(INSTANT_ENDPOINT, {
  sector_id: parseInt(sector_id),
  strategy,
  url
})

export const getSectors = () => getter(SECTORS_ENDPOINT)

export const getDataset = () => getter(DATASET_ENDPOINT)

export const verifyUrl = (url) => getter(URL_CHECK_ENDPOINT, { url })

async function getter(endpoint, parameters = null) {
  const url = BASE_URL + endpoint
  const params = parameters ? '?' + new URLSearchParams(parameters) : ''
  const response = await fetch(url + params, {
    headers: {
      accept: 'application/json'
    }
  })

  if (!response.ok) {
    throw new Error(`Get ${endpoint} failed with status ${response.status}`)
  }

  return response.json()
}

// import { sectors, datasets } from './mock'

// export const getSectors = () => {
//   return Promise.resolve(sectors)
// }
// export const getBarometer = (sectorId, strategy) => {
//   return Promise.resolve(
//     datasets.find(
//       (data) => data.strategy === strategy && data.sectorId === sectorId
//     )
//   )
// }
