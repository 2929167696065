export default {
  namespaced: true,
  state: {
    showHeader: true,
  },
  getters: {
    showHeader: (state) =>state.showHeader,
  },
  mutations: {
    setShowHeader(state, showHeader) {
      state.showHeader = showHeader;
    },
  },
};
