<template>
  <div class="scrollHint">
    <p>{{ isMobile ? 'Swipe' : 'Scroll' }}</p>

    <div class="scrollIcon"></div>
  </div>
</template>

<script>
export default {
  name: 'ScrollHint',
  computed: {
    isMobile() {
      return !this.$vuetify.breakpoint.smAndUp;
    },
  },
};
</script>
