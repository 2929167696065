<script>
import BaseSmallIndicator from '@/components/barometer/smallIndicator/SiteBestIndicator.vue'

export default {
  extends: BaseSmallIndicator,
  name: 'SmallIndicatorSiteWorstIndicator',
  computed: {
    metric() {
      let worstMetric = null
      Object.values(this.barometer.instant).forEach((metric) => {
        if (metric.code.indexOf('score_') !== 0) {
          return
        }
        if (!worstMetric || metric.instant < worstMetric.instant) {
          worstMetric = metric
        }
      })
      return worstMetric
    },
    title() {
      return `Moins bon indicateur : ${this.metricCode}`;
    }
  }
}
</script>
