import Vue from 'vue';
import Vuex from 'vuex';

import sector from '@/store/sector';
import header from '@/store/header';
import instant from '@/store/instant';
import website from '@/store/website';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    header,
    instant,
    sector,
    website,
  },
});

export default store;
