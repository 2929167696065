<template>
  <div class="d-flex justify-center">
    <div class="menu-text-box">
      <p class="menu-text menuFooterText tekoText">
        <a class="menu-link" target="_blank" href="https://www.synolia.com">
          MADE WITH <span :class="loveColor">LOVE</span> BY SYNOLIA
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loveColor: String,
  },
};
</script>
