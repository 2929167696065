<template>
  <v-card class="small-indicator pa-4" v-if="isValid">
    <v-card-title class="small-indicator-header d-block">
      <p class="name">{{ title }}</p>
      <p class="grade">{{ score }}/100</p>
    </v-card-title>
    <v-card-text class="d-flex">
      <div class="indicator-part" :class="leftExtraClass">
        <span class="icon"><v-icon>{{ leftIcon }}</v-icon></span>
        <div class="right-part">
          <span class="mark">{{ leftScoreText }}</span>
          <span class="mark-info">{{ leftText }}</span>
        </div>
      </div>
      <div class="indicator-part" :class="rightExtraClass">
        <span class="icon"><v-icon>{{ rightIcon }}</v-icon></span>
        <div class="right-part">
          <span class="mark">{{ rightScoreText }}</span>
          <span class="mark-info">{{ rightText }}</span>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BaseSmallIndicator',
  props: {
    barometer: { type: Object, required: true }
  },
  computed: {
    title() {
      return 'Titre.'
    },
    score() {
      return 0
    },
    leftScore() {
      return 0
    },
    leftScoreText() {
      return this.leftScore > 0 ? `+${this.leftScore}%` : `${this.leftScore}%`
    },
    leftText() {
      return 'Texte de gauche.'
    },
    leftExtraClass() {
      if (this.leftScore === 0) {
        return 'is-neutral';
      }
      return this.leftScore > 0 ? 'is-up' : 'is-down';
    },
    leftIcon() {
      if (this.leftScore === 0) {
        return 'horizontal_rule';
      }
      return this.leftScore > 0 ? 'arrow_upward' : 'arrow_downward'
    },
    rightScore() {
      return 0
    },
    rightScoreText() {
      return this.rightScore > 0
        ? `+${this.rightScore}%`
        : `${this.rightScore}%`
    },
    rightText() {
      return 'Texte de droite.'
    },
    rightExtraClass() {
      if (this.rightScore === 0) {
        return 'is-neutral';
      }
      return this.rightScore >= 0 ? 'is-up' : 'is-down'
    },
    rightIcon() {
      if (this.rightScore === 0) {
        return 'horizontal_rule';
      }
      return this.rightScore >= 0 ? 'arrow_upward' : 'arrow_downward'
    },
    isValid() {
      return false
    }
  },
  methods: {
    /**
     * Make a number to 2 decimal max
     * @param n
     * @returns {number}
     */
    to2Decimal(n) {
      return parseFloat(n.toFixed(2))
    }
  }
}
</script>
