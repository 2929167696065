<template>
  <BaseSimpleSmallIndicator
    :score="`${average}/100`"
    title="Skor de mon secteur"
    :isBig="isBig"
  />
</template>

<script>
import BaseSimpleSmallIndicator from '@/components/barometer/smallIndicator/simple/BaseSimpleSmallIndicator.vue';

export default {
  name: 'SimpleSmallIndicatorSectorAverage',
  components: { BaseSimpleSmallIndicator },
  props: {
    instant: { type: Object, required: true },
    isBig: { type: Boolean },
  },
  computed: {
    average() {
      return parseInt(this.instant.scores.score['28days']);
    },
  },
};
</script>
