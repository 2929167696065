<template>
  <v-hover v-slot="{ hover }">
    <v-btn
      :large="large"
      variant="tonal"
      :color="hover ? secondaryColor : primaryColor"
      class="white--text tekoText"
      :width="!isMobile ? (width ? width : 200) : null"
      :class="extraClass"
      @click="() => $emit('click')"
      :href="href"
      :target="target || '_self'"
      :loading="loading"
      :disabled="loading"
    >
      <span v-if="!isMobile">{{ hover ? secondaryText : primaryText }}</span>
      <v-icon v-else color="white" large>{{ mobileIcon }}</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'SwitchingButton',
  props: {
    primaryText: { type: String },
    secondaryText: { type: String },
    primaryColor: { type: String },
    secondaryColor: { type: String },
    mobileIcon: { type: String },
    isMobile: { type: Boolean, required: false },
    width: { type: String },
    href: { type: String },
    large: { type: Boolean, required: false },
    extraClass: { type: String },
    disabled: { type: Boolean },
    loading: { type: Boolean },
    target: { type: String },
  },
};
</script>
