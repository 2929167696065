<template>
  <div class="sticky-toolbar" :style="headerStyle">
    <v-toolbar class="site-header" comfortable height="68" align="center" fixed>
      <v-container>
        <div class="d-flex justify-space-between">
          <HeaderLogo version="dark" />
          <v-spacer></v-spacer>
          <SwitchingButton
            primaryText="Je teste mon site"
            primaryColor="reveBleu"
            secondaryText="et j'obtiens mon skor"
            secondaryColor="vieEnRose"
            :isMobile="isMobile"
            mobileIcon="fas fa-rocket"
            extraClass="headerButton"
            @click="() => goToInstant()"
          />
          <MenuDialog />
        </div>
      </v-container>
    </v-toolbar>
  </div>
</template>

<script>
import MenuDialog from '@/components/menu/MenuDialog';
import { ROUTE_INSTANT } from '@/router';
import { mapGetters } from 'vuex';
import HeaderLogo from './menu/HeaderLogo.vue';
import SwitchingButton from './utils/SwitchingButton.vue';

export default {
  components: {
    MenuDialog,
    HeaderLogo,
    SwitchingButton,
  },
  data: () => ({
    showSidebar: false,
  }),
  computed: {
    ...mapGetters({
      showHeader: 'header/showHeader',
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    headerStyle() {
      if (this.showHeader) {
        return {
          opacity: 1,
          'top': '0',
        };
      }
      return {
        opacity: 0,
        'top': '-68px',
        'pointer-events': 'none',
      };
    },
  },
  methods: {
    goToInstant() {
      this.$router.push({ name: ROUTE_INSTANT })
    }
  },
  created() {
    this.showSidebar = !this.isMobile;
  },
};
</script>
