<template>
  <v-btn
    icon
    dark
    @mouseover="mouseHoverLogo"
    @mouseleave="mouseLeaveLogo"
    @click="onClick"
  >
    <div class="logo-btn">
      <div class="menu-text-box">
        <img
          class="sk-logo"
          :src="this.getSKpart()"
          height="62"
          style="transform: translateX(68px)"
        />
      </div>
      <div class="menu-text-box">
        <img
          class="or-logo"
          :src="this.getORpart()"
          height="62"
          style="transform: translateX(-68px)"
        />
      </div>
      <div class="logo-background-letters">
        <div
          :class="version === 'light' ? 'light' : null"
          class="skor logo-random-move logo-skor-letter letter-s1"
          data-power="2"
          data-speed="3"
        ></div>
        <div
          :class="version === 'light' ? 'light' : null"
          class="skor logo-random-move logo-skor-letter letter-s2"
          data-power="2"
          data-speed="3"
        ></div>
        <div
          :class="version === 'light' ? 'light' : null"
          class="skor logo-random-move logo-skor-letter letter-k"
          data-power="2"
          data-speed="3"
        ></div>
        <div
          :class="version === 'light' ? 'light' : null"
          class="skor logo-random-move logo-skor-letter letter-o"
          data-power="2"
          data-speed="3"
        ></div>
        <div
          :class="version === 'light' ? 'light' : null"
          class="skor logo-random-move logo-skor-letter letter-r"
          data-power="2"
          data-speed="3"
        ></div>
      </div>
    </div>
  </v-btn>
</template>

<script>
import { ROUTE_HOME } from '@/router';
import anime from 'animejs';
import $ from 'jquery';

export default {
  data: () => ({
    isHovered: false,
    animeDuration: 750,
    animeEasing: 'cubicBezier(.2, .6, .35, 1)',
    animeOffset: 70,
    randomMoveAnim: null,
  }),
  props: {
    version: { type: String },
  },
  methods: {
    onClick() {
      this.$router.push({ name: ROUTE_HOME });
    },
    getDistance(x1, y1, x2, y2) {
      let y = x2 - x1;
      let x = y2 - y1;

      return Math.sqrt(x * x + y * y);
    },
    randomMove(element) {
      var scale = 1;
      if ($(element).css('scale') > 0) {
        scale = $(element).css('scale');
      }
      var scaler = 1 / scale;

      var power = 10;
      if ($(element).data('power')) {
        power = $(element).data('power');
      }

      var speed = 10;
      if ($(element).data('speed')) {
        speed = $(element).data('speed');
      }

      var randomX = anime.random(-power * scaler, power * scaler);
      var randomY = anime.random(-power * scaler, power * scaler);
      var matrix = $(element)
        .css('transform')
        .replace(/[^0-9\-.,]/g, '')
        .split(',');
      var x = matrix[12] || matrix[4];
      var y = matrix[13] || matrix[5];

      if (!x) x = 0;
      if (!y) y = 0;

      var distance = this.getDistance(randomX, randomY, x, y);

      this.randomMoveAnim = anime({
        targets: element,
        translateX: function () {
          return randomX;
        },
        translateY: function () {
          return randomY;
        },
        easing: 'linear',
        duration: function () {
          return (distance * 1000) / speed;
        },
        complete: () => {
          if (element.classList.contains('logo-random-move')) {
            this.randomMove(element);
          }
        },
      });
    },
    mouseHoverLogo() {
      if (!this.isHovered) {
        this.isHovered = true;
        anime({
          targets: '.sk-logo',
          translateX: 0,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.or-logo',
          translateX: 0,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-s1',
          translateX: -32.5,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-s2',
          translateX: -32.5,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-k',
          translateX: -7,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-o',
          translateX: 5,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-r',
          translateX: 46,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
      }
    },
    mouseLeaveLogo() {
      if (this.isHovered) {
        this.isHovered = false;
        anime({
          targets: '.sk-logo',
          translateX: this.animeOffset,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.or-logo',
          translateX: -this.animeOffset,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-s1',
          translateX: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-s2',
          translateX: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-k',
          translateX: 0,
          translateY: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-o',
          translateX: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
        anime({
          targets: '.letter-r',
          translateX: 0,
          duration: this.animeDuration,
          easing: this.animeEasing,
        });
      }
    },
    getImgUrl(logo) {
      if (this.version) {
        return require('../../assets/images/' + logo);
      }
    },
    getSKpart() {
      if (this.version && this.version === 'light') {
        return require('../../assets/images/sk_light.png');
      } else {
        return require('../../assets/images/SK.png');
      }
    },
    getORpart() {
      if (this.version && this.version === 'light') {
        return require('../../assets/images/or_light.png');
      } else {
        return require('../../assets/images/OR.png');
      }
    },
  },
};
</script>
