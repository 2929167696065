<template>
  <v-row>
    <v-col cols="12">
      <h2>
        Nos polices
        <span>Parce qu'à deux, c'est mieux</span>
      </h2>
    </v-col>

    <v-col cols="6">
      <h3 class="tekoText">Teko</h3>
      <p class="tekoText">
        CETTE POLICE EST UTILISÉ DANS LES TITRES, SOUS TITRES ET BOUTONS,
        TOUJOURS EN MAJUSCULES ET EN REGULAR
      </p>
    </v-col>
    <v-col cols="6">
      <h3 class="popinsText">Poppins</h3>
      <p class="popinsText">
        Cette police est utilisée pour les textes courants et paragraphes en
        regular, jamais en majuscules, et
        <strong>
          quelques fois en bold pour plus de lisibilité et d'impact.
        </strong>
      </p>
    </v-col>

    <v-col cols="12">
      <h2>
        Typographies
        <span>De belles informations avec de beaux titres</span>
      </h2>
      <p>Avoir un span dans un titre lui donne un sous-titre</p>
    </v-col>

    <v-col cols="12">
      <h1>
        Headline 01
        <span>Headline 01 - Titre complémentaire coloré</span>
      </h1>
      <br />
    </v-col>

    <v-col cols="12">
      <h2>
        Headline 02
        <span>Headline 02 - Titre complémentaire coloré</span>
      </h2>
      <br />
    </v-col>

    <v-col cols="12">
      <h3>
        Headline 03
        <span>Headline 03 - Titre complémentaire coloré</span>
      </h3>
      <br />
    </v-col>

    <v-col cols="12">
      <h4>
        Headline 04
        <span>Headline 04 - Titre complémentaire coloré</span>
      </h4>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AppStyleGuideFonts'
}
</script>
