<template>
  <div class="site-tester text-center" id="siteTestForm">
    <div class="background-letters">
      <div class="skor random-move skor-letter letter-s1"></div>
      <div class="skor random-move skor-letter letter-s2"></div>
      <div class="skor random-move skor-letter letter-k"></div>
      <div class="skor random-move skor-letter letter-o"></div>
      <div class="skor random-move skor-letter letter-r"></div>
    </div>
    <v-container class="site-tester-container">
      <v-row>
        <v-col cols="12" class="site-checker">
          <h2 class="site-checker-title mb-4">
            La web performance
            <br/>
            est une science exacte
          </h2>
          <p class="reveBleu--text popinsText site-checker-subtitle">
            Renseignez l'url de votre site e-commerce et obtenez votre Skor :
          </p>
        </v-col>

        <v-col cols="12" sm="6" class="pa-sm-6">
          <v-text-field
            v-model="url"
            :label="$t('Home.Form.Url.label') + '*'"
            type="input"
            outlined
            :placeholder="$t('Home.Form.Url.placeholder')"
            hide-details="auto"
            color="blueVelvet"
            class="urlInput"
            :rules="urlRules"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <SectorSelector v-model="selectedSector" />
        </v-col>

        <v-col cols="12">
          <SwitchingButton
            primaryText="Je teste mon site"
            primaryColor="reveBleu"
            secondaryText="Et j'obtiens mon Skor !"
            secondaryColor="vieEnRose"
            @click="() => onClick()"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SectorSelector from '@/components/barometer/SectorSelector.vue';
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { ROUTE_INSTANT } from '@/router';
import { URL_RULES } from '@/utils/form/rules';

export default {
  name: 'SiteTestForm',
  components: { SwitchingButton, SectorSelector },
  data() {
    return {
      url: '',
      selectedSector: null,
      urlRules: URL_RULES,
    };
  },
  methods: {
    onClick() {
      this.$router.push({ name: ROUTE_INSTANT, params: {
        sector: this.selectedSector,
        url: this.url,
        device: 'desktop'
      } })
    }
  }
}
</script>
