<template>
  <div class="big-panel db">
    <div class="content">
      <v-container>
        <v-row>
          <v-col lg="2" md="1" class="d-none d-md-block"></v-col>
          <v-col cols="12" lg="8" md="10" class="">
            <h2>Notre base de données</h2>
            <p class="popinsText">Avec déjà plusieurs milliers d'urls enregistrées, notre base de données vous permet de comparer votre web performance avec des concurrents appartenant au même domaine d'activité que vous.</p>
          </v-col>
          <v-col lg="2" md="1" class="d-none d-md-block"></v-col>
        </v-row>

        <v-row>
          <v-col lg="2" md="1" class="d-none d-md-block"></v-col>
          <v-col cols="12" lg="8" md="10" class="">
            <v-row class="numbers">
              <v-col cols="12" md="4" class="number">
                <span class="prefix">+</span><input class="animated" data-animation="counter" data-to="2600" data-play="true" type="text" disabled value="0" />
                <span class="subtitle">URLS enregistrées</span>
              </v-col>

              <v-col cols="12" md="4" class="number">
                <input class="animated" data-animation="counter" data-to="19" data-play="true" type="text" disabled value="0" />
                <span class="subtitle">Secteurs d'activité</span>
              </v-col>

              <v-col cols="12" md="4" class="number">
                <input class="animated" data-animation="counter" data-to="28" data-play="true" type="text" disabled value="0" />
                <span class="subtitle">Jours d'historique</span>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="2" md="1" class="d-none d-md-block"></v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="buttons">
            <div class="buttons">
              <SwitchingButton
                primaryText="Je teste mon site"
                primaryColor="purpleRain"
                secondaryText="Je teste mon site"
                secondaryColor="reveBleu"
                href="#siteTestForm"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import SwitchingButton from '@/components/utils/SwitchingButton.vue';

export default {
  name: 'DataBases',
  components: { SwitchingButton },
};
</script>
