export default {
  namespaced: true,
  state: {
    // Contains a mapping <siteUrl, instantData>
    mobile: {},
    desktop: {},
  },
  getters: {
    instant: state => (({ device, url }) => state[device][url]), // eslint-disable-line prettier/prettier
  },
  mutations: {
    addInstant(state, { device, url, instant }) {
      if (!state[device]) {
        throw new Error(`Unknown device "${device}"`);
      }
      state[device][url] = instant;
    },
  },
};
