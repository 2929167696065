<template>
  <v-row>
    <v-col cols="12">
      <h2>
        Les boutons
        <span>Il faut cliquer!</span>
      </h2>
    </v-col>

    <v-col cols="12">
      <h3>
        Boutons classiques
        <span>Classique</span>
      </h3>
    </v-col>

    <v-col cols="12">
      <v-row>
        <v-col><v-btn x-small>x-small</v-btn></v-col>
        <v-col><v-btn small>small</v-btn></v-col>
        <v-col><v-btn>Default</v-btn></v-col>
        <v-col><v-btn large>Large</v-btn></v-col>
        <v-col><v-btn x-large>x-Large</v-btn></v-col>
        <v-col><v-btn loading>Loading</v-btn></v-col>
        <v-col><v-btn disabled>Disabled</v-btn></v-col>
        <v-col>
          <v-btn rounded x-large color="cactusDudu" class="popinsText">
            Arrondi XL
          </v-btn>
        </v-col>
        <v-col cols="12"><v-btn block>Block</v-btn></v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <h3>
        Boutons colorés
        <span>Un peu plus fun</span>
      </h3>
    </v-col>

    <v-col cols="12">
      <v-btn color="primary" class="ma-2">Primary</v-btn>
      <v-btn color="error" class="ma-2">Error</v-btn>

      <v-btn color="blueVelvet" class="ma-2">Default</v-btn>
      <v-btn color="soleilEte" class="ma-2">Soleil d'été</v-btn>
      <v-btn color="reveBleu" class="ma-2">Un rêve bleu</v-btn>
      <v-btn color="cactusDudu" class="ma-2">Le cactus à Dudu</v-btn>
      <v-btn color="purpleRain" class="ma-2">Purple rain</v-btn>
      <v-btn color="vieEnRose" class="ma-2">La vie en rose</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ButtonStyleGuide'
}
</script>
