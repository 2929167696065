<script>
import BaseSmallIndicator from '@/components/barometer/smallIndicator/BaseSmallIndicator.vue'

export default {
  extends: BaseSmallIndicator,
  name: 'SmallIndicatorSiteBestIndicator',
  computed: {
    metric() {
      let bestMetric = null
      Object.values(this.barometer.instant).forEach((metric) => {
        if (metric.code.indexOf('score_') !== 0) {
          return
        }
        if (!bestMetric || metric.instant > bestMetric.instant) {
          bestMetric = metric
        }
      })
      return bestMetric
    },
    metricCode() {
      return this.metric.code.replace('score_', '').toUpperCase();
    },
    title() {
      return `Meilleur indicateur : ${this.metricCode}`;
    },
    score() {
      return this.to2Decimal(this.barometer.instant[this.metric.code].instant);
    },
    leftScore() {
      const result = ((this.score * 100) / this.barometer.scores[this.metric.code]['7days']) - 100; // eslint-disable-line prettier/prettier
      return this.to2Decimal(result);
    },
    leftText() {
      return `Par rapport à la moyenne ${this.metricCode} du secteur sur 7 jours`;
    },
    rightScore() {
      const result = ((this.score * 100) / this.barometer.scores[this.metric.code]['28days']) - 100 // eslint-disable-line prettier/prettier
      return this.to2Decimal(result)
    },
    rightText() {
      return `Par rapport à la note ${this.metricCode} du meilleur site du secteur`
    },
    isValid() {
      return this.barometer.instant
    }
  }
}
</script>
