<template>
  <Select
    :value="value"
    :items="sortedSectors"
    :loading="loading"
    :label="$t('Common.sector')"
    :aria-label="$t('Common.sector')"
    return-object
    itemText="name"
    loader-height="5px"
    @input="(value) => $emit('input', value)"
    @click="(value) => $emit('click', value)"
    :rules="rules"
  />
</template>

<script>
import { mapActions } from 'vuex';
import Select from '@/components/utils/Select';
export default {
  name: 'SectorSelector',
  components: {
    Select,
  },
  props: {
    value: { type: String },
    loading: { type: Boolean, required: false },
  },
  data: () => ({
    rules: [(v) => v !== null],
    sectors: [],
  }),
  computed: {
    sortedSectors() {
      return [...this.sectors]
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((sector) => ({
          name: sector.name,
          value: sector.id.toString(),
        }));
    },
  },
  methods: {
    ...mapActions({
      getSectors: 'sector/getSectors',
    }),
  },
  async created() {
    this.sectors = await this.getSectors();
  },
};
</script>
