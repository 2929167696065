<template>
  <div v-if="!isMobile" class="custom-cursor-container">
    <div
      v-for="(trail, index) in trails"
      :id="`cursor-trail-${index}`"
      :key="index"
      ref="cursorTrail"
      class="custom-cursor-trail"
      :style="{
        top: trail.y - 11 + 'px',
        left: trail.x - 11 + 'px',
        width: `${trail.size}px`,
        height: `${trail.size}px`,
      }"
    ></div>
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" class="not-displayed">
      <defs>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
          <feColorMatrix
            in="blur"
            mode="matrix"
            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
            result="goo"
          />
          <feBlend in="SourceGraphic" in2="goo" />
        </filter>
      </defs>
    </svg>
  </div>
</template>

<script>
import $ from 'jquery';
import anime from 'animejs/lib/anime.es.js';

export default {
  data() {
    return {
      isMobile: false,
      mouseX: -50,
      mouseY: -50,
      trails: [], // Array to store the cursor trails
      trailLength: 6, // Number of trailing elements
      trailOpacity: 1, // Initial opacity of the trailing elements
      trailFadeSpeed: 0.1, // Speed at which the trailing elements fade out
      trailResize: 2, // Speed at which the trailing elements fade out
      slimeAnime: false,
    };
  },
  computed: {
    userAgentData: () => navigator.userAgentData,
    userAgent: () => navigator.userAgent,
    isAndroid: () => navigator.userAgent.includes('Android'),
    isIphone: () => navigator.userAgent.includes('iPhone'),
  },
  mounted() {
    if (
      (this.userAgentData && this.userAgentData.mobile) ||
      this.isAndroid ||
      this.isIphone
    ) {
      this.isMobile = true;
      return;
    }

    $('body').addClass('notMobile');
    document.addEventListener('mousemove', this.updateMousePosition);
    requestAnimationFrame(this.animateTrail);
  },
  destroyed() {
    document.removeEventListener('mousemove', this.updateMousePosition);
  },
  methods: {
    updateMousePosition(event) {
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;

      if (!this.slimeAnime) {
        anime({
          targets: '#cursor-trail-1',
          translateX: -5,
          translateY: 5,
          direction: 'alternate',
          easing: 'easeInOutQuad',
          duration: 1300,
          autoplay: true,
          loop: true,
        });
        anime({
          targets: '#cursor-trail-2',
          translateX: 5,
          translateY: 5,
          direction: 'alternate',
          easing: 'easeInOutQuad',
          duration: 800,
          autoplay: true,
          loop: true,
        });
        anime({
          targets: '#cursor-trail-3',
          translateX: 5,
          translateY: -5,
          direction: 'alternate',
          easing: 'easeInOutQuad',
          duration: 1200,
          autoplay: true,
          loop: true,
        });
        anime({
          targets: '#cursor-trail-4',
          translateX: 0,
          translateY: -5,
          direction: 'alternate',
          easing: 'easeInOutQuad',
          duration: 1600,
          autoplay: true,
          loop: true,
        });
        this.slimeAnime = true;
      }
    },
    animateTrail() {
      const trail = {
        x: this.mouseX,
        y: this.mouseY,
        opacity: this.trailOpacity,
        size: 26,
      };
      this.trails.push(trail);

      if (this.trails.length > this.trailLength) {
        this.trails.shift();
      }

      let trailSize = 20;

      this.trails.forEach((trail) => {
        trail.opacity -= this.trailFadeSpeed;
        trail.size = trailSize;
        if (trail.opacity < 0) {
          trail.opacity = 0;
        }
        trailSize += this.trailResize;
      });

      requestAnimationFrame(this.animateTrail);
    },
  },
};
</script>
