<template>
  <div ref="elem"></div>
</template>

<script>
import ScrollMagic from 'scrollmagic';
import { mapMutations } from 'vuex';

/**
 * Hide header if the window is not scrolled until this element
 * Show it after
 */
export default {
  name: 'HeaderToggler',
  props: {
    scrollController: Object,
  },
  data() {
    return {
      scene: null,
    };
  },
  methods: {
    ...mapMutations({
      setShowHeader: 'header/setShowHeader',
    }),
    listenToScroll() {
      const element = this.$refs.elem;
      this.scene = new ScrollMagic.Scene({
        triggerElement: element,
        duration: '0%',
        triggerHook: 0,
      })
        .on('progress', (scroll) => {
          const seekValue = scroll.progress;
          this.toggleHeader(!!seekValue);
        })
        .setPin(element)
        .addTo(this.scrollController);
    },
    toggleHeader(show) {
      this.setShowHeader(show);
    },
  },
  mounted() {
    this.toggleHeader(false);
    this.listenToScroll();
  },
  beforeDestroy() {
    this.toggleHeader(true);
    this.scene.remove();
  },
};
</script>
