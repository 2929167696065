<template>
  <v-container class="centered-container">
    <v-row>
      <v-col cols="12">
        <Header :title="$t('Dataset.header1', { urlCount })" />
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="dataset"
          :items-per-page="dataset.length"
          :loading="isLoading"
          dense
          hide-default-footer
          :header-props="{ sortIcon: 'keyboard_arrow_up' }"
          sort-by="name"
        >
          <template #item.status="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"
                  ><span class="ml-2 status" :class="item.status"> </span
                ></span>
              </template>
              <span>{{ getTooltip(item.status) }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Header from '@/components/utils/Header'
import { getSectors } from '@/modules/api'

export default {
  components: { Header },
  data: () => ({
    isLoading: false,
    dataset: []
  }),
  computed: {
    headers() {
      return [
        { text: this.$t('Dataset.TabHeaders.sector'), value: 'name' },
        { text: this.$t('Dataset.TabHeaders.accounts'), value: 'totalAccount' },
        { text: this.$t('Dataset.TabHeaders.urls'), value: 'totalUrls' },
        {
          text: this.$t('Dataset.TabHeaders.urlsActive'),
          value: 'totalUrlsActive'
        },
        { text: this.$t('Dataset.TabHeaders.updatedAt'), value: 'updatedAt' },
        { text: this.$t('Dataset.TabHeaders.status'), value: 'status' },
        { text: this.$t('Dataset.TabHeaders.desktop'), value: 'totalDesktop' },
        { text: this.$t('Dataset.TabHeaders.mobile'), value: 'totalMobile' }
      ]
    },
    urlCount() {
      return this.dataset.reduce(
        (total, sector) => (total += parseInt(sector.totalUrls)),
        0
      )
    }
  },
  methods: {
    async loadDataset() {
      this.isLoading = true
      const sectors = await getSectors()
      this.dataset = sectors.map((sector) => ({
        name: sector.name,
        totalAccount: sector.websites,
        totalUrls: sector.urls,
        totalUrlsActive: sector.valid,
        updatedAt:
          new Date(sector.date).toLocaleString().replace(/(.*)\D\d+/, '$1') +
          ' (UTC+1)',
        status: this.getStatus(sector.quality),
        totalDesktop: parseFloat(sector.desktop).toFixed(2),
        totalMobile: parseFloat(sector.mobile).toFixed(2)
      }))
      // Solution temporaire
      // TODO: enlever 'Démos et Solutions internes' de api.my-skor.io
      this.dataset = this.dataset.filter(
        (data) => data.name !== 'Démos et Solutions internes'
      )
      this.isLoading = false
    },
    getRandomDate() {
      const randomDateStart = new Date(2021, 8, 17)
      const randomDateEnd = new Date()
      const randomDate = new Date(
        randomDateStart.getTime() +
          Math.random() * (randomDateEnd.getTime() - randomDateStart.getTime())
      )
      return randomDate.toLocaleString(undefined, {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    },
    getStatus(quality) {
      if (quality >= 0.8) {
        return 'green'
      } else if (quality >= 0.5) {
        return 'orange'
      } else {
        return 'red'
      }
    },
    getTooltip(status) {
      switch (status) {
        case 'green':
          return this.$t('Dataset.ScoreTooltip.green')
        case 'orange':
          return this.$t('Dataset.ScoreTooltip.orange')
        case 'red':
          return this.$t('Dataset.ScoreTooltip.red')
        default:
          break
      }
    }
  },
  created() {
    this.loadDataset()
  }
}
</script>
