<template>
  <v-container class="centered-container">
    <v-row>
      <h1>
        {{ $t('404.NotFoundTitle') }}
        <span>{{ $t('404.NotFoundText') }}</span>
      </h1>
    </v-row>
    <v-row>
      <h4>{{ $t('404.ErrorCode') }}: 404</h4>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NotFoundView',
};
</script>
