<template>
  <v-container class="mt-12" :loaded="loaded">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" md="6" sm="10">
        <v-row justify="center">
          <h1 class="blueVelvet--text instantPageTitle">{{ $t('Instant.title') }}</h1>
        </v-row>
        <v-row justify="center">
          <p class="text-center instantPageSubtitle">
            {{ $t('Instant.intro') }}
          </p>
        </v-row>
      </v-col>
    </v-row>
    <InstantForm
      v-on:instantLoaded="onInstantLoaded"
      :sector="sector"
      :device="device"
      :url="url"
    />
    <v-slide-y-transition mode="out-in" v-if="showEmpty">
      <EmptyInstant :date="date" @dateChange="onDateChange" />
    </v-slide-y-transition>
    <v-slide-y-transition mode="out-in" v-else>
      <v-row v-if="instant">
        <template>
          <v-col cols="12" align="center" class="instantIntro my-8">
            <h3 class="instantIntroTitle reveBleu--text mb-5">
              {{ $t('Instant.title2') }}
            </h3>
            <p>
              Chaque indicateur est pondéré dans votre Skor, selon les calculs
              de Google.<br />Par souci de lisibilité et de compréhension,
              chaque indicateur est converti en note sur 100 pour interpréter le
              résultat, sans connaissance technique.
            </p>
          </v-col>
          <v-col cols="12" align="left" class="mb-5">
            <InstantDateSelector :date="date" @change="onDateChange" />
          </v-col>

          <v-col cols="12" md="6">
            <SimpleSmallIndicatorMySkor :instant="instant" :isBig="true" />
          </v-col>
          <v-col cols="12" md="6" class="pt-0 px-md-3 px-0">
            <v-row>
              <v-col cols="12" md="6">
                <SimpleSmallIndicatorBestMeric
                    :instant="instant"
                    :isBig="false"
                />
              </v-col>
              <v-col cols="12" md="6">
                <SimpleSmallIndicatorWorstMetric
                    :instant="instant"
                    :isBig="false"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6">
                <SimpleSmallIndicatorSectorAverage
                  :instant="instant"
                  :isBig="false"
                />
              </v-col>
              <v-col cols="12" md="6">
                <SimpleSmallIndicatorBestSkor :instant="instant" :isBig="false" />
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            align="center"
            class="px-10 my-10 instantIntermediary"
          >
            <p class="mb-6">
              <strong>
                Vous souhaitez en savoir plus sur votre web performance ?
              </strong>
              <br />Analysez l'évolution de la web performance de votre site et
              de votre secteur concurrentiel en demandant votre rapport détaillé
              sur une période de 28 jours.
            </p>

            <SwitchingButton
              primaryText="J'obtiens mon rapport"
              primaryColor="reveBleu"
              secondaryText="J'obtiens mon rapport"
              secondaryColor="vieEnRose"
              @click="() => getReport()"
            />
          </v-col>

          <InstantBigIndicators :instant="instant" :getReport="getReport" />
        </template>
      </v-row>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import SimpleSmallIndicatorBestMeric from '@/components/barometer/smallIndicator/simple/BestMetric.vue';
import SimpleSmallIndicatorBestSkor from '@/components/barometer/smallIndicator/simple/BestSkor.vue';
import SimpleSmallIndicatorMySkor from '@/components/barometer/smallIndicator/simple/mySkor.vue';
import SimpleSmallIndicatorSectorAverage from '@/components/barometer/smallIndicator/simple/SectorAverage.vue';
import SimpleSmallIndicatorWorstMetric from '@/components/barometer/smallIndicator/simple/WorstMetric.vue';
import InstantBigIndicators from '@/components/instant/BigIndicators.vue';
import InstantDateSelector from '@/components/instant/DateSelector.vue';
import EmptyInstant from '@/components/instant/EmptyInstant.vue';
import InstantForm from '@/components/instant/Form.vue';
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { INDICATOR_TYPES } from '@/modules/config';
import { ROUTE_INSTANT, ROUTE_INSCRIPTION } from '@/router';

export default {
  components: {
    InstantDateSelector,
    EmptyInstant,
    InstantBigIndicators,
    SwitchingButton,
    SimpleSmallIndicatorBestSkor,
    SimpleSmallIndicatorBestMeric,
    SimpleSmallIndicatorWorstMetric,
    SimpleSmallIndicatorSectorAverage,
    SimpleSmallIndicatorMySkor,
    InstantForm,
  },
  data() {
    return {
      INDICATOR_TYPES,
      loaded: false,
      url: null,
      sector: null,
      device: null,
      instant: null,
      date: 'today',
    };
  },
  computed: {
    defaultUrl() {
      return this.$route.params.url;
    },
    defaultSector() {
      return this.$route.params.sector;
    },
    defaultDevice() {
      return this.$route.params.device;
    },
    showEmpty() {
      return this.date !== 'today';
    },
  },
  methods: {
    onInstantLoaded({ instant, sector, device, url }) {
      this.instant = instant;
      this.loaded = true;
      this.updateRoute({ sector, device, url });
    },
    updateRoute({ sector, device, url }) {
      if (
        sector !== this.defaultSector ||
        device !== this.defaultDevice ||
        url !== this.defaultUrl
      ) {
        this.$router.push({
          name: ROUTE_INSTANT,
          params: { sector, device, url },
        });
      }
    },
    getReport() {
      this.$router.push({ name: ROUTE_INSCRIPTION });
    },
    onDateChange(date) {
      this.date = date;
    },
  },
  created() {
    if (this.defaultDevice) {
      this.device = this.defaultDevice;
    }
    if (this.defaultUrl) {
      this.url = this.defaultUrl;
    }
    if (this.defaultSector) {
      this.sector = this.defaultSector;
    }
  },
};
</script>
