<template>
  <BaseSimpleSmallIndicator
    :score="`${diff} pts`"
    title="Écart entre mon site et celui de mon secteur"
    :isBig="isBig"
  />
</template>

<script>
import BaseSimpleSmallIndicator from '@/components/barometer/smallIndicator/simple/BaseSimpleSmallIndicator.vue';

export default {
  name: 'SimpleSmallIndicatorDiffWithBest',
  components: { BaseSimpleSmallIndicator },
  props: {
    instant: { type: Object, required: true },
    isBig: { type: Boolean },
  },
  computed: {
    diff() {
      const my = this.instant.instant.score.instant;
      const winner = this.instant.winner.score;
      return parseInt(my > winner ? my - winner : winner - my);
    },
  },
};
</script>
