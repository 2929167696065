<template>
  <BaseSimpleSmallIndicator
    :score="`${score}/100`"
    :title="`Mon meilleur indicateur: ${metricCode}`"
    :isBig="isBig"
  />
</template>

<script>
import BaseSimpleSmallIndicator from '@/components/barometer/smallIndicator/simple/BaseSimpleSmallIndicator.vue';

export default {
  name: 'SimpleSmallIndicatorBestMeric',
  components: { BaseSimpleSmallIndicator },
  props: {
    instant: { type: Object, required: true },
    isBig: { type: Boolean },
  },
  computed: {
    metric() {
      let bestMetric = null;
      Object.values(this.instant.instant).forEach((metric) => {
        if (metric.code.indexOf('score_') !== 0) {
          return;
        }
        if (!bestMetric || metric.instant > bestMetric.instant) {
          bestMetric = metric;
        }
      });
      return bestMetric;
    },
    metricCode() {
      return this.metric.code.replace('score_', '').toUpperCase();
    },
    score() {
      return parseInt(this.instant.instant[this.metric.code].instant);
    },
  },
};
</script>
