<template>
  <div class="barometerIndicatorHeader" :class="className">
    <img
      v-if="image"
      class="image"
      :src="image.src"
      :width="image.width"
      :height="image.height"
      :alt="title"
    />
    <div class="title-part">
      <div class="pre-title">
        {{ preTitle }}

        <BarometerIndicatorInfoModal
          v-if="hasInfoButton"
          :title="title"
          :preTitle="preTitle"
          :type="type"
          :image="image"
        />
      </div>
      <p class="header-title">{{ title }}</p>
    </div>
  </div>
</template>
<script>
import BarometerIndicatorInfoModal from '@/components/barometer/indicator/InfoModal.vue';
import { INDICATOR_TYPES } from '@/modules/config';
import s1 from '@/assets/images/S-logo1.png';
import s2 from '@/assets/images/S-logo2.png';
import r from '@/assets/images/R-logo.png';
import o from '@/assets/images/O-logo.png';
import k from '@/assets/images/K-logo.png';
export default {
  name: 'BarometerIndicatorHeader',
  components: { BarometerIndicatorInfoModal },
  props: {
    type: String,
    hasInfoButton: Boolean,
  },
  computed: {
    className() {
      return `barometerIndicatorHeader${this.type.toUpperCase()}`;
    },
    preTitle() {
      return this.$t('Indicators.' + this.type + '.subtitle');
    },
    title() {
      return this.$t('Indicators.' + this.type + '.title');
    },
    image() {
      switch (this.type) {
        case INDICATOR_TYPES.LCP:
          return {
            src: s1,
            width: 57,
            height: 98,
          };
        case INDICATOR_TYPES.FCP:
          return {
            src: k,
            width: 32,
            height: 90,
          };
        case INDICATOR_TYPES.TBT:
          return {
            src: o,
            width: 19,
            height: 90,
          };
        case INDICATOR_TYPES.CLS:
          return {
            src: r,
            width: 36,
            height: 18,
          };
        case INDICATOR_TYPES.SI:
          return {
            src: s2,
            width: 85,
            height: 71,
          };
      }
      return null;
    },
  },
};
</script>
