<template>
  <div id="and-after-page">
    <AfterBlocksWithLogo :scrollController="scrollController" />

    <AutoScrollingTextLoop
      :duration="15"
      text="La mesure de la performance est aujourd'hui une science exacte, appuyée par des codes et des algorithmes dont seul Google a le secret."
    />

    <AfterLayeredBlocks :scrollController="scrollController" />
    <div class="and-after-copyright-container">
      <v-container class="and-after-copyright py-10 align-center">
        <v-row>
          <v-col md="2" class="d-none d-md-block"></v-col>
          <v-col cols="12" md="8">
            <h2 class="mb-12 and-after-copyright-title">
              Skor est un outil développé par Synolia, comme une vitrine
              technologique de notre expertise en web performance.
              <span class="mt-6">
                Vous souhaitez réaliser un audit de votre web performance ? vous
                avez besoin d'un accompagnement pour la mise en place d'actions
                concrètes visant à optimiser votre web performance ?
                contactez-nous !
              </span>
            </h2>
            <SwitchingButton
              primaryText="Contactez Synolia !"
              primaryColor="reveBleu"
              secondaryText="Contactez Synolia !"
              secondaryColor="vieEnRose"
              href="https://www.synolia.com/contact/"
              target="_blank"
            />
          </v-col>
          <v-col md="2" class="d-none d-md-block"></v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import AfterBlocksWithLogo from '@/components/after/BlocksWithLogo.vue';
import AfterLayeredBlocks from '@/components/after/LayeredBlocks.vue';
import AutoScrollingTextLoop from '@/components/AutoScrollingTextLoop.vue';
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import ScrollMagic from 'scrollmagic';

export default {
  name: 'AfterView',
  components: {
    AutoScrollingTextLoop,
    SwitchingButton,
    AfterLayeredBlocks,
    AfterBlocksWithLogo,
  },
  data() {
    return {
      scrollController: new ScrollMagic.Controller(),
    };
  },
};
</script>
