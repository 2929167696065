<template>
  <v-card
    v-if="valid"
    :class="extraClass"
    class="barometerIndicator histogramBarometerIndicator mb-0"
  >
    <v-card-title>
      <Header :type="type" :hasInfoButton="true" />
    </v-card-title>
    <v-card-text>
      <HistogramIndicatorMark :type="type" :score="score" />
      <canvas class="canvas" :id="canvasId" ref="canvas"></canvas>
      <div class="histoLegends">
        <div
          v-for="(data, i) in graphData"
          :key="`histo-legend-${i}`"
          class="histoLegend"
        >
          {{ data.label }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import HistogramIndicatorMark from '@/components/barometer/histogramIndicator/Mark.vue';
import { Chart, BarController, BarElement, Tooltip } from 'chart.js';
import Header from '@/components/barometer/indicator/Header.vue';
import { INDICATOR_TYPES, INDICATOR_COLORS } from '@/modules/config';

// We need to register EVERY element from Chart to use it
Chart.register({
  Tooltip,
  BarController,
  BarElement,
});
export default {
  name: 'HistogramIndicator',
  components: { HistogramIndicatorMark, Header },
  props: {
    type: String,
    instant: Object,
  },
  data() {
    return {
      chart: null,
    };
  },
  computed: {
    canvasId() {
      return `histogram-indicator-canva-${this.type}`;
    },
    valid() {
      return Object.values(INDICATOR_TYPES).includes(this.type);
    },
    extraClass() {
      switch (this.type) {
        case INDICATOR_TYPES.LCP:
          return 'indicatorLCP';
        case INDICATOR_TYPES.FCP:
          return 'indicatorFCP';
        case INDICATOR_TYPES.TBT:
          return 'indicatorTBT';
        case INDICATOR_TYPES.CLS:
          return 'indicatorCLS';
        case INDICATOR_TYPES.SI:
          return 'indicatorSI';
      }
      return '';
    },
    score() {
      return this.instant.instant[`score_${this.type}`].instant;
    },
    graphData() {
      return [
        {
          label: `Mon Skor ${this.type}`,
          value:
            this.instant.instant[`score_${this.type.toLowerCase()}`].instant,
          color: INDICATOR_COLORS[this.type].primary,
        },
        {
          label: 'Skor de mon secteur',
          value:
            this.instant.history[this.instant.history.length - 1][
              `score_${this.type.toLowerCase()}`
            ],
          color: INDICATOR_COLORS[this.type].secondary,
        },
        {
          label: 'Skor du meilleur site de mon secteur',
          value: this.instant.winner[`score_${this.type.toLowerCase()}`],
          color: INDICATOR_COLORS[this.type].tertiary,
        },
      ];
    },
  },
  methods: {
    destroyChart() {
      if (this.chart) {
        this.chart.destroy();
        this.chart = null;
      }
    },
    createChart() {
      const canvas = document.getElementById(this.canvasId);
      const config = {
        type: 'bar',
        data: {
          labels: this.graphData.map(() => ''), // d.label),
          datasets: [
            {
              data: this.graphData.map((d) => d.value),
              backgroundColor: this.graphData.map((d) => d.color),
              borderRadius: 5,
              maxBarThickness: 40,
            },
          ],
        },
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      };
      this.chart = new Chart(canvas, config);
    },
  },
  mounted() {
    this.destroyChart();
    if (!this.valid) {
      return;
    }
    this.createChart();
  },
};
</script>
