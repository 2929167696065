<template>
  <BaseSimpleSmallIndicator
    :score="`${skor}/100`"
    title="Mon skor"
    :isBig="isBig"
  />
</template>

<script>
import BaseSimpleSmallIndicator from '@/components/barometer/smallIndicator/simple/BaseSimpleSmallIndicator.vue';

export default {
  name: 'SimpleSmallIndicatorMySkor',
  components: { BaseSimpleSmallIndicator },
  props: {
    instant: { type: Object, required: true },
    isBig: { type: Boolean },
  },
  computed: {
    skor() {
      return parseInt(this.instant.instant.score.instant);
    },
  },
};
</script>
