<template>
  <v-col :cols="cols" :style="{ color }" class="barometerIndicatorLegend">
    <div
      class="barometerIndicatorLegendSymbol"
      :style="{ backgroundColor: color }"
    ></div>
    {{ label }}
  </v-col>
</template>

<script>
export default {
  name: 'BarometerIndicatorLegend',
  props: {
    cols: Number,
    label: String,
    color: String
  }
}
</script>
