<template>
  <v-app :class="appExtraClass">
    <SlimyCursor></SlimyCursor>
    <HeaderToolbar />
    <v-main class="background">
      <v-scroll-y-transition mode="out-in">
        <router-view></router-view>
      </v-scroll-y-transition>
    </v-main>
    <Footer />
    <RandomMover />
  </v-app>
</template>

<script>
import RandomMover from '@/components/RandomMover.vue';
import SlimyCursor from '@/components/SlimyCursor.vue';
import HeaderToolbar from '@/components/HeaderToolbar';
import Footer from '@/components/Footer';
import {
  ROUTE_INSCRIPTION,
  ROUTE_INSTANT,
  ROUTE_CGU,
  ROUTE_HOME,
} from '@/router';
import { mapMutations } from 'vuex';

export default {
  components: { RandomMover, HeaderToolbar, Footer, SlimyCursor },
  name: 'app',
  computed: {
    appExtraClass() {
      if ([ROUTE_INSTANT, ROUTE_INSCRIPTION, ROUTE_CGU].includes(this.$route.name)) {
        return `no-bg-page`;
      }
      return '';
    },
    metaTitle() {
      return this.$route.meta.title || 'Skor';
    },
  },
  watch: {
    $route() {
      document.title = this.metaTitle;
      this.updateHeaderDisplay();
    },
  },
  methods: {
    ...mapMutations({
      setShowHeader: 'header/setShowHeader',
    }),
    updateHeaderDisplay() {
      // On top of page, header is hidden for homepage
      if (this.$route.name === ROUTE_HOME) {
        this.setShowHeader(false);
        return;
      }
      this.setShowHeader(true);
    },
  },
  created() {
    document.title = this.metaTitle;
    this.updateHeaderDisplay();
  },
  mounted() {
    const script = document.createElement('script');
    script.innerHTML = `
      var _paq = window._paq = window._paq || [];
      /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="https://synolia.matomo.cloud/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '${process.env.VUE_APP_MATOMO_ID}']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src='//cdn.matomo.cloud/synolia.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
      })();`;
    document.head.appendChild(script);
  },
};
</script>

<style>
body {
  min-width: 320px;
}
.height-100 {
  height: 100%;
}
.centered-container {
  max-width: 1432px;
}
</style>
