<template>
  <v-container class="centered-container">
    <v-row>
      <v-col class="text-center mb-5">
        <h1 class="h2" v-if="!isSubmitted">
          J'obtiens mon rapport
          <span>Vous souhaitez en savoir plus sur votre web performance ?</span>
        </h1>
        <h1 class="h2" v-else>
          Merci pour votre demande
          <span>
            Vous recevrez un e-mail rapidement. Surveillez votre boîte mail !
          </span>
        </h1>
      </v-col>
    </v-row>

    <v-row v-if="!isSubmitted">
      <v-col md="3" class="d-none d-md-block"></v-col>
      <v-col cols="12" md="6" class="text-center">
        <p>
          Analysez l'évolution de la web performance de votre site et de votre
          secteur concurrentiel en demandant votre rapport détaillé sur une
          période de 28 jours.
        </p>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col md="3" class="d-none d-md-block"></v-col>
      <v-col cols="12" md="6" class="text-center">
        <p class="mb-10">
          En attendant, vous pouvez comparer votre Skor de web performance du
          jour avec votre secteur d'activité et retrouver vos indicateurs :
        </p>
        <SwitchingButton
          primaryText="Je teste mon site"
          primaryColor="reveBleu"
          secondaryText="et j'obtiens mon skor"
          secondaryColor="vieEnRose"
          mobileIcon="fas fa-rocket"
          extraClass="headerButton"
          @click="() => goToInstant()"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="3" class="d-none d-md-block"></v-col>
      <v-col cols="12" md="6">
        <div id="formGoesHere" :class="isSubmitted ? 'hidden' : ''">
          <!--
          The HTML will be replaced, but
          If the class is not present in templates, the style is removed
          So we add the classes here for now
          -->
          <div
            class="
              hs-form-field
              field
              error
              no-list
              hs-error-msgs hs-button
              no-bg-page
              logoParts-step3
              logoParts-step2
              logoParts-step1
              logoParts-step0
              barometerIndicatorHeaderLCP
              barometerIndicatorHeaderTBT
              barometerIndicatorHeaderFCP
              barometerIndicatorHeaderCLS
              barometerIndicatorHeaderSI
            "
          ></div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SwitchingButton from '@/components/utils/SwitchingButton.vue';
import { ROUTE_INSTANT } from '@/router';

export default {
  name: 'InscriptionView',
  components: { SwitchingButton },
  data() {
    return {
      isSubmitted: false,
    };
  },
  methods: {
    addScript() {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/embed/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '5416253',
          formId: '8a8f60cd-2269-4cba-adf9-db4d9029dfde',
          target: '#formGoesHere',
          css: '', // If specified, the form is not in a iframe
          onFormSubmitted: () => this.onSubmit(),
        });
      });
    },
    onSubmit() {
      this.isSubmitted = true;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    goToInstant() {
      this.$router.push({ name: ROUTE_INSTANT });
    },
  },
  mounted() {
    this.addScript();
  },
};
</script>
