<template>
  <v-row>
    <v-col cols="12" class="mb-2">
      <h2>
        Modale
        <span>(Clique sur le bouton)</span>
      </h2>
    </v-col>

    <v-dialog v-model="dialog" width="700">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="red lighten-2" dark v-bind="attrs" v-on="on">
          Ouvrir la modale
        </v-btn>
      </template>

      <v-card class="pa-6">
        <v-card-title class="text-h5 d-block mb-6">
          <h2 class="text-center">
            Je teste mon site
            <span>Et j'obtiens mon skor !</span>
          </h2>
        </v-card-title>

        <v-card-text>
          <p class="text-center mb-6">
            Votre site n'appartient pas encore à notre base de données ?
            Soumettez-le et recevez votre rapport sur votre web performance.
          </p>

          <v-form>
            <v-text-field
              v-model="model.url"
              type="input"
              outlined
              hide-details="auto"
              color="blueVelvet"
              label="URL"
              required
              class="mb-5"
            ></v-text-field>

            <v-text-field
              v-model="model.email"
              type="input"
              outlined
              hide-details="auto"
              color="blueVelvet"
              label="Votre email"
              required
              class="mb-5"
            ></v-text-field>

            <!-- <p>RECAPTCHA?</p> -->
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn large color="reveBleu" @click="dialog = false">
            Je teste mon site
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  name: 'ModalStyleGuide',
  data() {
    return {
      dialog: false,
      model: {
        url: '',
        email: ''
      }
    }
  }
}
</script>
