import Vue from 'vue'
import Vuex from 'vuex';
import LoadScript from 'vue-plugin-load-script'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueTour from 'vue-tour'
import store from './store/index';

require('vue-tour/dist/vue-tour.css')
Vue.use(VueTour)

// Import style files
import './scss/index.scss'

Vue.config.productionTip = false

Vue.use(Vuex);
new Vue({
  router,
  vuetify,
  i18n,
  LoadScript,
  store,
  render: (h) => h(App)
}).$mount('#app')
